import React, { FC } from 'react';
import Button from "../../atoms/Button"
import InputArea from "../../molecules/InputArea"
import { ReactComponent as Logo } from "../../../assets/icon/eazii-cart-logo-with-text.svg"
import StyledLoginDialog from "./LoginDialog.styled"

type LoginDialog = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  email: {
    value: string,
    onChange: (param:React.ChangeEvent<HTMLInputElement>)=>void,
  },
  password: {
    value: string,
    onChange: (param:React.ChangeEvent<HTMLInputElement>)=>void,
  },
  loginFailed: boolean,
}

const LoginDialog: FC<LoginDialog> = ({ onSubmit, email, password, loginFailed }) => (
    <StyledLoginDialog>
        <Logo style={{ width: "276px", marginBottom: "30px" }} />
        <form onSubmit={onSubmit} id={"form"} className={(loginFailed) ? "input_errors" : ""}>
            <InputArea
              labelTitle="ログインID"
              label="e-mail"
              type='email'
              name='email'
              placeholder="ログインID"
              {...email}
            />
            <InputArea
              labelTitle="パスワード"
              label="password"
              type='password'
              name='password'
              placeholder="パスワード"
              autoComplete="on"
              {...password}
            />
            {loginFailed &&
                <p style={{ fontSize: "14px", color: "#C04949" }}>
                    ログインできませんでした。IDとパスワードを確認してください。
                </p>
            }
            <Button value="ログイン" type="submit" />
        </form>
    </StyledLoginDialog>
);

export default LoginDialog;