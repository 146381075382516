import React,{ FC, useState } from 'react';
import PropTypes from 'prop-types';
import { rows ,RowsProps } from '../../utils/sampleData'
// material
import { makeStyles } from '@material-ui/core/styles';
import {
  ClearOutlined as ClearOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from '@material-ui/core';

import {sort} from "../../utils"

  const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    popover: {
        pointerEvents: 'none',
    },
    paperPopover: {
        padding: theme.spacing(1),
        color: 'white',
        background: 'black',
        boxShadow: 'none',
        fontWeight: 'bold',
    },
    tableNowrap :{
        whiteSpace: 'nowrap'
    }
}));



type EnhancedTableHeadProps = {
    onSelectAllClick:(event:React.ChangeEvent<HTMLInputElement>)=>void;
    order:'asc' | 'desc' ;
    orderBy:string;
    numSelected:number;
    rowCount:number;
    onRequestSort:(param:keyof RowsProps)=>void;
}

const headCells = [
    { id: 'id', disablePadding: true, label: 'ID' },
    { id: 'img', disablePadding: true, label: '画像' },
    { id: 'name', disablePadding: true, label: '商品名' },
    { id: 'code',  disablePadding: false, label: 'コード' },
    { id: 'price',  disablePadding: false, label: '価格' },
    { id: 'stock',  disablePadding: false, label: '在庫数' },
    { id: 'open',  disablePadding: false, label: '公開状態' },
    { id: 'period',  disablePadding: false, label: '販売期間' },
] as const;

const EnhancedTableHead : FC<EnhancedTableHeadProps> = ({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
})=> {
    const createSortHandler = (property:keyof RowsProps) => () => onRequestSort(property)
    const classes = useStyles();
    
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align="left"></TableCell>
            </TableRow>
        </TableHead>
    );
}


const EnhancedTable:FC = () => {
    const [order, setOrder] = useState<"asc" | "desc">('asc');
    const [orderBy, setOrderBy] = useState<keyof RowsProps>('id');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl1, setAnchorEl1] = useState<EventTarget & SVGSVGElement | null>(null);
    const [anchorEl2, setAnchorEl2] = useState<EventTarget & SVGSVGElement | null>(null);
    const [anchorEl3, setAnchorEl3] = useState<EventTarget & SVGSVGElement | null>(null);

    const handlePopoverOpen1 = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => setAnchorEl1(event.currentTarget);
    const handlePopoverOpen2 = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => setAnchorEl2(event.currentTarget);
    const handlePopoverOpen3 = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => setAnchorEl3(event.currentTarget);

    const handlePopoverClose1 = () => setAnchorEl1(null);
    const handlePopoverClose2 = () => setAnchorEl2(null);
    const handlePopoverClose3 = () => setAnchorEl3(null);

    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    const classes = useStyles();

    const handleRequestSort = (property: keyof RowsProps) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event:React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (name:string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected:string[] = [] 
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };
    const isSelected = (name:string) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {
                            sort(rows, order, orderBy)
                                .slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)
                                .map((row, index):JSX.Element => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={()=>handleClick(row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.id}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                <img src={row.img} />
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{color: '#FF8C3E'}}
                                                className={classes.tableNowrap}
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                {row.code}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                {row.price}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                {row.stock}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                {row.open ? '公開' : '非公開'}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableNowrap}
                                            >
                                                {row.period}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={classes.tableNowrap}
                                            >
                                                <VisibilityOutlinedIcon
                                                    aria-owns={
                                                        open1
                                                            ? 'mouse-over-popover'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen1}
                                                    onMouseLeave={handlePopoverClose1}
                                                    fontSize="default"
                                                    style={{ margin: '0 29px' }}
                                                />
                                                <FileCopyOutlinedIcon
                                                    aria-owns={
                                                        open2
                                                            ? 'mouse-over-popover'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen2}
                                                    onMouseLeave={handlePopoverClose2}
                                                    fontSize="default"
                                                    style={{ margin: '0 29px' }}
                                                />
                                                <ClearOutlinedIcon
                                                    aria-owns={
                                                        open3
                                                            ? 'mouse-over-popover'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen3}
                                                    onMouseLeave={handlePopoverClose3}
                                                    fontSize="default"
                                                    style={{ margin: '0 29px' }}
                                                />
                                                <Popover
                                                    id="mouse-over-popover"
                                                    className={classes.popover}
                                                    classes={{
                                                        paper:
                                                            classes.paperPopover,
                                                    }}
                                                    open={open1}
                                                    anchorEl={anchorEl1}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose1}
                                                    disableRestoreFocus
                                                >
                                                    表示
                                                </Popover>
                                                <Popover
                                                    id="mouse-over-popover"
                                                    className={classes.popover}
                                                    classes={{
                                                        paper:
                                                            classes.paperPopover,
                                                    }}
                                                    open={open2}
                                                    anchorEl={anchorEl2}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose2}
                                                    disableRestoreFocus
                                                >
                                                    複製
                                                </Popover>
                                                <Popover
                                                    id="mouse-over-popover"
                                                    className={classes.popover}
                                                    classes={{
                                                        paper:
                                                            classes.paperPopover,
                                                    }}
                                                    open={open3}
                                                    anchorEl={anchorEl3}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose3}
                                                    disableRestoreFocus
                                                >
                                                    廃止
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default EnhancedTable
