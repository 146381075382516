import React, { FC, useEffect, useState } from "react";
import { NestDataObject, FieldError, Controller } from "react-hook-form";
// components
import AccordionWrapper from "../organisms/AccordionWrapper";
import GridForm from "../molecules/GridForm";
import ImageUpload from "../organisms/ImageUpload";
import PriceInputGroup from "../organisms/PriceInputGroup";
import SelectPeriodDate from "../../SelectPeriodDate";
import PreOrderCheckBox from "../molecules/PreOrderCheckBox";
import CollapseGroupPrice from "../organisms/CollapseGroupPrice";
import CollapseDelivery from "../organisms/CollapseDelivery";
import { ItemHookFormValue } from "../../type";

import { makeStyles } from "@material-ui/styles";
import { FormControl, Grid, MenuItem, OutlinedInput, Select, Typography } from "@material-ui/core";
import { AddBox as AddBoxIcon } from "@material-ui/icons";

const useStyles = makeStyles({
    selectInput: {
        padding: "11px!important"
    },
    inputStockContainer: {
        padding: "11px 13px"
    }
});
type Picture = {
    img: File;
    id: string;
};

type Props = {
    errors: NestDataObject<ItemHookFormValue, FieldError>;
    register: any;
    dailyNecessities: boolean;
    deletePicture: (id: string) => void;
    onDrop: (files: File[], pictures: string[]) => void;
    pictures: Picture[];
    setOrderStartAt: React.Dispatch<React.SetStateAction<Date>>;
    setOrderEndAt: React.Dispatch<React.SetStateAction<Date>>;
    orderStartAt: Date;
    orderEndAt: Date;
    startAt: Date;
    endAt: Date;
    setStartAt: React.Dispatch<React.SetStateAction<Date>>;
    setEndAt: React.Dispatch<React.SetStateAction<Date>>;
    collapseSalesPrice: "same" | "each";
    salesPriceWithoutTax: string;
    collapseDeliveryFee: "same" | "each";
    control: any;
};

const BasicFormTemplate: FC<Props> = ({
    errors,
    register,
    dailyNecessities,
    deletePicture,
    onDrop,
    pictures,
    setOrderEndAt,
    setOrderStartAt,
    orderStartAt,
    orderEndAt,
    startAt,
    endAt,
    setStartAt,
    setEndAt,
    salesPriceWithoutTax,
    collapseSalesPrice,
    collapseDeliveryFee,
    control
}) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(collapseSalesPrice);
    useEffect(() => {
        setCollapse(collapseSalesPrice);
    }, [collapseSalesPrice, collapseDeliveryFee]);
    return (
        <AccordionWrapper title="基本設定">
            <Grid container spacing={2}>
                <GridForm title="商品ID" helpIcon>
                    0
                </GridForm>
                <GridForm title="商品名" required>
                    <OutlinedInput
                        fullWidth
                        name="itemName"
                        multiline
                        margin="dense"
                        inputRef={register({ required: <p className={"form-error"}>必須項目です</p> })}
                    />
                </GridForm>
                <GridForm title="商品種別" helpIcon>
                    <Controller
                        control={control}
                        name="kind"
                        defaultValue="normal"
                        as={
                            <Select
                                classes={{ outlined: classes.selectInput }}
                                fullWidth
                                variant="outlined"
                                onChange={e => {
                                    console.log(e.target.value);
                                }}
                            >
                                <MenuItem value="normal">通常商品</MenuItem>
                                <MenuItem value="bargain">お買い得商品</MenuItem>
                                <MenuItem value="premium">特別商品</MenuItem>
                            </Select>
                        }
                    />
                </GridForm>
                <GridForm align="flex-start" title="画像アップロード" helpIcon>
                    <ImageUpload pictures={pictures} deletePicture={deletePicture} onDrop={onDrop} />
                </GridForm>
                <GridForm align="flex-start" title="商品説明" helpIcon required>
                    <OutlinedInput
                        multiline={true}
                        notched={true}
                        color="primary"
                        name="description"
                        rows={7}
                        fullWidth
                        placeholder="1000文字以内 産地、色、素材、重さ、注意点など"
                        inputRef={register({
                            required: <p className={"form-error"}>必須項目です</p>,
                            maxLength: {
                                value: 1000,
                                message: <p className={"form-error"}>1000文字以上は入力できません</p>
                            }
                        })}
                    />
                    {errors.description && errors.description.message}
                    <Typography style={{ fontSize: 13, margin: "8px 0 17px" }}>
                        <AddBoxIcon
                            style={{
                                verticalAlign: "top",
                                color: "#333333",
                                fontSize: 18,
                                cursor: "pointer"
                            }}
                        />
                        <span>一覧コメントを追加</span>
                    </Typography>
                </GridForm>
                <PriceInputGroup errors={errors} register={register} dailyNecessities={dailyNecessities} />
                <GridForm title="在庫数" required helpIcon>
                    <OutlinedInput
                        inputRef={register({ required: <p className={"form-error"}>必須項目です</p> })}
                        name={"amount"}
                        notched={true}
                        type={"number"}
                        classes={{ input: classes.inputStockContainer }}
                    />
                    {errors.amount && errors.amount.message}
                </GridForm>
                <GridForm title="注文受付期間" required helpIcon>
                    <SelectPeriodDate
                        setStartAt={setOrderStartAt}
                        setEndAt={setOrderEndAt}
                        startAt={orderStartAt}
                        endAt={orderEndAt}
                    />
                </GridForm>
                <PreOrderCheckBox register={register} name={"salesPeriodDate"} label="無制限" />
                <GridForm title="価格有効期間" required helpIcon>
                    <SelectPeriodDate setStartAt={setStartAt} setEndAt={setEndAt} startAt={startAt} endAt={endAt} />
                </GridForm>
                <GridForm title="価格設定" required helpIcon>
                    <FormControl variant="outlined" fullWidth>
                        <Controller
                            control={control}
                            name="group"
                            defaultValue="same"
                            as={
                                <Select classes={{ outlined: classes.selectInput }} fullWidth variant="outlined">
                                    <MenuItem value="same">すべての顧客に同じ販売価格を表示</MenuItem>
                                    <MenuItem value="each">顧客グループによって異なる販売価格を表示</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>
                </GridForm>
                <CollapseGroupPrice
                    register={register}
                    collapseSalesPrice={collapse}
                    salesPriceWithoutTax={salesPriceWithoutTax}
                    errors={errors}
                />
                <CollapseDelivery register={register} collapseDeliveryFee={collapse} />
            </Grid>
        </AccordionWrapper>
    );
};

export default BasicFormTemplate;
