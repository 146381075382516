import React, { useEffect, useContext, useState, FC } from "react";
import clsx from "clsx";
// material ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Route, Switch, useHistory } from "react-router-dom";
// material icons
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// routes
import Delivery from "../pages/Delivery";
import { activeSidebarItem } from "../../recoil/rootData";
import { useRecoilState } from "recoil";
import firebase from "../../assets/Firebase.js";

import { AuthContext } from "../AuthService";

import { createRoute } from "./routes";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        backgroundColor: "#EDE8E5"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    sidebarItem: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#E2D6CF"
        }
    }
}));

type Props = {
    initialPath: string;
};

const Sidebar: FC<Props> = ({ initialPath }) => {
    const user = useContext(AuthContext);
    const sidebarRoutes = createRoute(user?.uid || "");
    const history = useHistory();
    const [activePath, setActivePath] = useRecoilState(activeSidebarItem);
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        history.push(initialPath);
        setActivePath(initialPath);
    }, []);
    const changePath = (path: string) => {
        if (activePath === path) return;
        history.push(path);
        setActivePath(path);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const onSignOut = () => {
        firebase.auth().signOut();
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                style={{ backgroundColor: "#442D29" }}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {process.env.REACT_APP_ADMIN ? "レモンシャーク管理画面" : "Eazii"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                <div style={{ backgroundColor: "#EDE8E5", height: "100vh" }}>
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider style={{ backgroundColor: "#DEC6C2" }} />
                    <List>
                        {sidebarRoutes.map(route => (
                            <React.Fragment key={route.path}>
                                <ListItem
                                    className={classes.sidebarItem}
                                    onClick={() => changePath(route.path)}
                                    style={route.path === activePath ? { backgroundColor: "#E2D6CF" } : {}}
                                >
                                    <ListItemIcon>{route.icon}</ListItemIcon>
                                    <ListItemText primary={route.name} />
                                </ListItem>
                                <Divider style={{ backgroundColor: "#DEC6C2" }} />
                            </React.Fragment>
                        ))}
                        <ListItem className={classes.sidebarItem} onClick={onSignOut}>
                            <ListItemIcon>{<ExitToAppIcon />}</ListItemIcon>
                            <ListItemText primary="ログアウト" />
                        </ListItem>
                        <Divider style={{ backgroundColor: "#DEC6C2" }} />
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    {sidebarRoutes.map(route => (
                        <Route exact path={route.path} component={route.component} />
                    ))}
                    <Route exact path={"/delivery/:id"} component={Delivery} />
                </Switch>
            </main>
        </div>
    );
};

export default Sidebar;
