import React, { FC } from 'react';
import StyledButton from "./Button.styled";

type Props = {
  value: string;
  type?: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
};

const Button: FC<Props> = ({ value, type, onClick }) => (
    <StyledButton type={type} onClick={onClick}>
        {value}
    </StyledButton>
);


export default Button;