import React, {FC} from "react";
import ImageUploader from "react-images-upload";
// material ui
import {makeStyles} from "@material-ui/styles"
import {
  Box,
  IconButton,
  Typography
} from "@material-ui/core"
import {
  CloudDownload as CloudDownloadIcon
} from "@material-ui/icons"

const useStyles = makeStyles({
  imgBox: {
    width: 170,
    height: 170,
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    position: 'relative',
  },
  hoverBg: {
    position: 'absolute',
    width: '100%',
    height: 170,
    textAlign: 'right',
    backgroundColor: 'rgba(0,0,0,0.5)',
    opacity: 0,
    '&:hover': {
        opacity: 100,
    }
  },
  close45: {
    position: "absolute",
    width: 17,
    height: 4,
    backgroundColor: '#442D29',
    transform: 'rotate(45deg)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  close135: {
    position: "absolute",
    width: 17,
    height: 4,
    backgroundColor: '#442D29',
    transform: 'rotate(135deg)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
})

type Picture ={
  img:File;
  id:string;
}

type Props = {
  pictures: Picture[],
  deletePicture: (id: string)=>void,
  onDrop: (files: File[], pictures: string[]) => void
}

const ImageUpload: FC<Props> = ({pictures, deletePicture, onDrop})=>{
  const classes = useStyles()
  return (
    <>
      <Box display='flex'>
        {pictures.length ? pictures.map((picture) => {
            //@ts-ignore
          const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
          const imageUrl = createObjectURL(picture.img)
            return (
            <div className={classes.imgBox} key={picture.id}>
                <div className={classes.hoverBg}>
                    <IconButton onClick={()=>{deletePicture(picture.id)}}>
                        <div style={{
                            width: 30,
                            height: 30,
                            backgroundColor: '#FFFFFF',
                            position: 'relative'
                        }}>
                            <span className={classes.close45} />
                            <span className={classes.close135} />
                        </div>
                    </IconButton>
                </div>
                <img src={imageUrl} style={{ width: '100%' }} />
            </div>)
        }) : null
        }
    </Box>
    <Typography style={{ margin: '20px 0' }}>項目の順番はドラッグ＆ドロップで変更可能です。</Typography>
    <div style={{
        border: "1px dashed #8B726E",
        display: 'flex',
        borderRadius: 4,
        opacity: 1,
    }}>
        <CloudDownloadIcon style={{
            fontSize: 70,
            margin: '60px 6px 0 20px',
            color: '#8B726E'
        }} />
        <ImageUploader
            name={"image"}
            withPreview={false}
            buttonText={"ファイルを選択"}
            label={"画像をドラッグ＆ドロップまたは"}
            onChange={onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
            withIcon={false}
            fileContainerStyle={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'initial',
                margin: 40,
                boxShadow: 'none',
            }}
            buttonStyles={{
                borderRadius: 4,
                border: 'solid 1px #595959',
                color: '#595959',
                background: '#fff',
                padding: '13px 15px',
                marginLeft: 12
            }}
        />
    </div>
    </>
  )
}

export default ImageUpload;