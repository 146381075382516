import React,{ FC } from 'react';
import { useRecoilValue } from "recoil";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import Progress from "../atoms/Progress";
import { groupedUsersState, usersState } from "../../recoil/rootData";
import { prefectures } from "../../utils/utils"


const birthMonth = ["none", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const Users:FC =()=> {
    const useStyles = makeStyles({
        root: {
            width: '100%',
            maxWidth: 500,
        },
        textBase: {
            color: "#000000"
        },
        checkboxMark: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        areaInput: {
            width: "46%"
        },
        buttonGroup: {
            borderColor: "#595959",
            backgroundColor: "#ffffff",
            color: "#595959",
            width: "170px"
        },
        pagenationButtonGroup: {
            borderColor: "#DDDDDD",
            backgroundColor: "#ffffff",
            color: "#FF8C3E",
            fontSize: "16px"
        }
    });

    const groupedUserState = useRecoilValue(groupedUsersState)
    const userState = useRecoilValue(usersState);
    const classes = useStyles();
    return (
            <React.Fragment>
                <main style={{ backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                        <Typography className={classes.textBase}>
                            会員ID・メールアドレス・お名前
                        </Typography>
                        <HelpIcon fontSize="small" style={{ color: "#333333", marginTop: "2px", marginLeft: "5px" }}/>
                    </div>
                    <TextField size="small" style={{ width: "60%" }} variant="outlined"/>
                    <Typography className={classes.textBase}>
                        <FormControlLabel
                                className={classes.textBase}
                                style={{ cursor: "default", fontWeight: "bolder" }}
                                control={
                                    <Checkbox
                                            className={classes.checkboxMark}
                                            style={{ cursor: "default" }}
                                            disableRipple
                                            indeterminate
                                            color="default"
                                            checked={true}
                                    />
                                }
                                label="詳細検索"
                        />
                    </Typography>
                    <FormGroup>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>会員種別</Typography>
                                <FormControlLabel
                                        className={classes.textBase}
                                        control={
                                            <Checkbox
                                                    name="checkedB"
                                                    color="primary"
                                            />
                                        }
                                        label="仮会員"
                                />
                                <FormControlLabel
                                        className={classes.textBase}
                                        control={
                                            <Checkbox
                                                    name="checkedB"
                                                    color="primary"
                                            />
                                        }
                                        label="本会員"
                                />
                                <FormControlLabel
                                        className={classes.textBase}
                                        control={
                                            <Checkbox
                                                    name="checkedB"
                                                    color="primary"
                                            />
                                        }
                                        label="退会"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>購入金額</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">￥</InputAdornment>,
                                            }}
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">￥</InputAdornment>,
                                            }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>性別</Typography>
                                <FormControlLabel
                                        className={classes.textBase}
                                        control={
                                            <Checkbox
                                                    name="checkedB"
                                                    color="primary"
                                            />
                                        }
                                        label="男性"
                                />
                                <FormControlLabel
                                        className={classes.textBase}
                                        control={
                                            <Checkbox
                                                    name="checkedB"
                                                    color="primary"
                                            />
                                        }
                                        label="女性"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>購入商品名</Typography>
                                <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>誕生月</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            variant="outlined"
                                            size="small"
                                            select
                                            defaultValue="none"
                                    >{birthMonth.map(month => {
                                        return month !== "none" ? (
                                                <MenuItem key={month} value={month}>
                                                    {month}月
                                                </MenuItem>
                                        ) : (
                                                <MenuItem key={month} value="none">
                                                    選択してください
                                                </MenuItem>
                                        )
                                    })}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>購入件数</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>誕生日</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>登録日</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>都道府県</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            variant="outlined"
                                            size="small"
                                            select
                                            defaultValue="none"
                                    >{prefectures.map(prefecture => {
                                        return prefecture !== "none" ? (
                                                <MenuItem key={prefecture} value={prefecture}>
                                                    {prefecture}
                                                </MenuItem>
                                        ) : (
                                                <MenuItem key={prefecture} value="none">
                                                    選択してください
                                                </MenuItem>
                                        )
                                    })}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>更新日</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>電話番号</Typography>
                                <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textBase}>最終更新日</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                    <Typography className={classes.textBase}
                                                style={{ lineHeight: "40px" }}> 〜 </Typography>
                                    <TextField
                                            className={classes.areaInput}
                                            variant="outlined"
                                            size="small"
                                            type="date"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                        <Button
                                style={{ width: 130, backgroundColor: "#FF8C3E", fontWeight: "bold", color: "#fff" }}
                                size="large"
                        >
                            検索
                        </Button>
                        <Typography className={classes.textBase} style={{ lineHeight: "40px", marginLeft: "15px" }}>
                            検索結果 : 56件が該当しました
                        </Typography>
                    </div>
                    <Typography className={classes.textBase} style={{ marginTop: "20px" }}>
                        <span style={{ fontWeight: "bold" }}>会員種別</span>：仮会員、本会員
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
                        <TextField
                                style={{ marginRight: "10px" }}
                                variant="outlined"
                                size="small"
                                select
                                defaultValue="10件"
                        >{<MenuItem value="10件">
                            10件
                        </MenuItem>}
                        </TextField>
                        <ButtonGroup color="default">
                            <Button startIcon={<CloudUploadIcon/>} className={classes.buttonGroup}>CSVダウンロード</Button>
                            <Button startIcon={<SettingsIcon/>} className={classes.buttonGroup}>CSV出力設定</Button>
                        </ButtonGroup>
                    </div>
                    <Grid container style={{ marginTop: "30px" }}>
                        <Grid item xs={2}>
                            <Typography className={classes.textBase} style={{ paddingLeft: "15px" }}>ID</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.textBase}>お名前</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.textBase}>電話番号</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textBase}>メールアドレス</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: "#8B726E", marginTop: "15px", marginBottom: "20px" }}/>
                        </Grid>
                        {
                            userState.map((user:{[param:string]:string}, index:number) => {
                                return (
                                        <>
                                            <Grid item xs={2}>
                                                <Typography className={classes.textBase}
                                                            style={{ paddingLeft: "15px" }}>
                                                    {index + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.textBase} style={{ color: "#FF8C3E" }}>
                                                    {user.userName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.textBase}>
                                                    {"0012345678"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography className={classes.textBase}>
                                                        {"1548553533.8807.mai.ekoda@example.net"}
                                                    </Typography>
                                                    <CloseIcon style={{ marginRight: "15px", color: "#442D29" }}/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider style={{
                                                    backgroundColor: "#8B726E",
                                                    marginTop: "20px",
                                                    marginBottom: "20px"
                                                }}/>
                                            </Grid>
                                        </>
                                )
                            })
                        }
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <ButtonGroup style={{ left: "0", right: "0" }}>
                                <Button className={classes.pagenationButtonGroup}>前へ</Button>
                                <Button className={classes.pagenationButtonGroup}>1</Button>
                                <Button className={classes.pagenationButtonGroup}
                                        style={{ color: "#fff", backgroundColor: "#FF8C3E" }}>2</Button>
                                <Button className={classes.pagenationButtonGroup}>3</Button>
                                <Button className={classes.pagenationButtonGroup}>次へ</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    {!groupedUserState && <Progress/>}
                    {/* {userState.length > 0 &&
              (
                  <Editable userData={userState} />
              )
              } */}
                </main>
            </React.Fragment>
    );
}

export default Users