type Locale = "ja-JP" | "en-US";
/**
 * %fmt% を日付時刻表記に。
 * 
 * @param date Dateオブジェクト
 * @param fmt フォーマット文字列、%YYYY%年%MM%月%DD%日、など。
 * @param locale 地域指定。デフォルト（入力なし）の場合はja-JP（日本）。現在他に対応しているのはen-US（英語）のみ。
 * @param pad パディング（桁数を埋める）文字列。デフォルト（入力なし）の場合は0。
 * @example
 * 例： 2016年03月02日15時24分09秒
    %YYYY%: 4桁年（2016）
    %YY%: 2桁年（16）
    %MMMM%: 月の長い表記、日本語では数字のみ、英語ではMarchなど（3）
    %MMM%: 月の短い表記、日本語では数字のみ、英語ではMar.など（3）
    %MM%: 2桁月（03）
    %M%: 月（3）
    %DD%: 2桁日（02）
    %D%: 日（2）
    %HH%: 2桁で表した24時間表記の時（15）
    %H%: 24時間表記の時（15）
    %h%: 2桁で表した12時間表記の時（03）
    %h%: 12時間表記の時（3）
    %A%: AM/PM表記（PM）
    %A%: 午前/午後表記（午後）
    %mm%: 2桁分（24）
    %m%: 分（24）
    %ss%: 2桁秒（09）
    %s%: 秒（9）
    %W%: 曜日の長い表記（水曜日）
    %w%: 曜日の短い表記（水）
 */
const dateToFormatString = (date: Date, fmt: string, locale: Locale = "ja-JP", pad: string = "0") => {
    const padding = (n: number, d: number, p: string) => {
        p = p || "0";
        return (p.repeat(d) + n).slice(-d);
    };
    const DEFAULT_LOCALE = "ja-JP";
    const getDataByLocale = (locale: Locale, obj: Record<Locale, string[]>, param: number) => {
        const array = obj[locale] || obj[DEFAULT_LOCALE];
        return array[param];
    };
    const format = {
        YYYY: () => padding(date.getFullYear(), 4, pad),
        YY: () => padding(date.getFullYear() % 100, 2, pad),
        MMMM: (locale: Locale) => {
            const month = {
                "ja-JP": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
                "en-US": [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ]
            };
            return getDataByLocale(locale, month, date.getMonth());
        },
        MMM: (locale: Locale) => {
            const month = {
                "ja-JP": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
                "en-US": [
                    "Jan.",
                    "Feb.",
                    "Mar.",
                    "Apr.",
                    "May",
                    "June",
                    "July",
                    "Aug.",
                    "Sept.",
                    "Oct.",
                    "Nov.",
                    "Dec."
                ]
            };
            return getDataByLocale(locale, month, date.getMonth());
        },
        MM: () => padding(date.getMonth() + 1, 2, pad),
        M: () => (date.getMonth() + 1).toString(),
        DD: () => padding(date.getDate(), 2, pad),
        D: () => date.getDate().toString(),
        HH: () => padding(date.getHours(), 2, pad),
        H: () => date.getHours().toString(),
        hh: () => padding(date.getHours() % 12, 2, pad),
        h: () => (date.getHours() % 12).toString(),
        mm: () => padding(date.getMinutes(), 2, pad),
        m: () => date.getMinutes().toString(),
        ss: () => padding(date.getSeconds(), 2, pad),
        s: () => date.getSeconds().toString(),
        A: () => (date.getHours() < 12 ? "AM" : "PM"),
        a: (locale: Locale) => {
            const ampm = {
                "ja-JP": ["午前", "午後"],
                "en-US": ["am", "pm"]
            };
            return getDataByLocale(locale, ampm, date.getHours() < 12 ? 0 : 1);
        },
        W: (locale: Locale) => {
            const weekday = {
                "ja-JP": ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
                "en-US": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
            };
            return getDataByLocale(locale, weekday, date.getDay());
        },
        w: (locale: Locale) => {
            const weekday = {
                "ja-JP": ["日", "月", "火", "水", "木", "金", "土"],
                "en-US": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]
            };
            return getDataByLocale(locale, weekday, date.getDay());
        }
    };
    const fmtstr = [""]; // %%（%として出力される）用に空文字をセット。
    Object.keys(format).forEach(key => {
        fmtstr.push(key); // ['', 'YYYY', 'YY', 'MMMM',... 'W', 'w']のような配列が生成される。
    });
    const re = new RegExp("%(" + fmtstr.join("|") + ")%", "g");
    // /%(|YYYY|YY|MMMM|...W|w)%/g のような正規表現が生成される。
    const replaceFn = (match: string, fmt: keyof typeof format) => {
        // match には%YYYY%などのマッチした文字列が、fmtにはYYYYなどの%を除くフォーマット文字列が入る。
        const func = format[fmt];
        // fmtがYYYYなら、format['YYYY']がfuncに代入される。つまり、
        // function() { return padding(date.getFullYear(), 4, pad); }という関数が代入される。
        if (func === undefined) {
            //存在しないフォーマット
            return match;
        }
        return func(locale);
    };
    return fmt.replace(re, replaceFn);
};

export default dateToFormatString;
