import React, { FC } from 'react';
import InputLabel from "../../atoms/InputLabel"
import InputText from "../../atoms/InputText"
import StyleInputArea from "./InputArea.styled"

type InputArea = {
    value: string,
    labelTitle: string,
    type: "text" | "email"  | "password" | "search" ;
    name: string,
    placeholder: string,
    autoComplete?: string,
    label: string,
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void),
}

const InputArea: FC<InputArea> = ({
    value,
    labelTitle,
    type,
    name,
    placeholder,
    autoComplete = '',
    label,
    onChange
}) => {
  
    return (
        <StyleInputArea>
            <InputLabel labelTitle={labelTitle} label={label} />
            <InputText
                value={value}
                type={type}
                name={name}
                placeholder={placeholder}
                autoComplete={autoComplete}
                label={label}
                onChange={onChange}
            />
        </StyleInputArea>
    );
}

export default InputArea;