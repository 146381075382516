import React, {FC,useEffect} from "react";
import { Route, BrowserRouter, Switch, } from "react-router-dom";
import Login from "./components/pages/Login"
import { AuthProvider } from "./components/AuthService";
import LoggedInRoute from "./components/LoggedInRoute";

import { RecoilRoot, } from 'recoil';
import Sidebar from "./components/Sidebar/Sidebar";
import ContentsEdit from "./components/pages/ContentsEdit";

import { theme } from "./utils"
import { ThemeProvider } from "@material-ui/core/styles"

const App:FC =() => {
    useEffect(()=>{
        document.title = process.env.REACT_APP_ADMIN?.toString() || 'Eazii Dashboard';
    },[])
    return (
            <RecoilRoot>
                <AuthProvider>
                    <BrowserRouter>
                      <ThemeProvider theme={theme}>
                        <Switch>
                            <Route exact path={'/login'} component={Login}/>
                            <Route exact path={'/contents'} component={ContentsEdit}/>
                            <LoggedInRoute path='/' redirect={"/admin/new"} component={Sidebar}/>
                        </Switch>
                      </ThemeProvider>
                    </BrowserRouter>
                </AuthProvider>
            </RecoilRoot>
    )
}

export default App;