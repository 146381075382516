import { Grid } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

type FormInLabelProps = {
    label: string;
    children: ReactNode;
    hasHr?: boolean;
    align?: "center" | "flex-start";
};

export const FormInLabel: FC<FormInLabelProps> = ({ label, children, hasHr, align }) => {
    return (
        <>
            <Label>
                <Grid alignItems={align} container item xs={12}>
                    <LabelText item xs={3}>
                        <span>{label}</span>
                    </LabelText>
                    <Grid item xs={9}>
                        {children}
                    </Grid>
                </Grid>
            </Label>
            {hasHr && <Hr />}
        </>
    );
};

const Label = styled.label`
    margin-left: 60px;
    padding: 20px 0;
    width: 100%;
    display: block;
`;

const LabelText = styled(Grid)`
    display: flex;
    align-items: center;
`;

const Hr = styled.hr`
    width: 100%;
    margin: 0;
`;

type FormContainerProps = {
    title?: string;
    children: ReactNode;
    notInput?: boolean;
};

export const FormContainer: FC<FormContainerProps> = ({ title, children, notInput }) => (
    <>
        {title && <Title xs={12}>{title}</Title>}
        {notInput ? (
            <Container>{children}</Container>
        ) : (
            <ContainerWrapper>
                <Container>{children}</Container>
            </ContainerWrapper>
        )}
    </>
);

const Title = styled(Grid)`
    height: 40;
    line-height: 40px;
    margin-left: 5px;
    margin-top: 100px;
`;

const Container = styled.div`
    width: 90%;
    margin: 0 auto;
`;

const ContainerWrapper = styled.div`
    background-color: #ffffff;
    border-top: 2px solid;
`;
