// register form
import React, { useEffect, useState, FC } from "react";
import ImageUploader from "react-images-upload";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { db, storage } from "../../assets/Firebase";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import shortid from "shortid";

//component
import Label from "../atoms/Label";

//recoil
import { useRecoilState } from "recoil";
import { itemTitlesState, usersState } from "../../recoil/rootData";

//material
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Button from "@material-ui/core/Button";
import HelpIcon from "@material-ui/icons/Help";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";

type HooksFormProps = {
    title: string;
    message: string;
};

type ParamsIdProps = {
    id: string;
};

type PictureProps = {
    img: File;
    id: string;
};

type ItemProps = {
    title: string;
    id: string;
};

type ItemsProps = {
    title: string;
    id: string | undefined;
    items?: ItemProps;
};

const Delivery: FC = () => {
    // hooks
    const [pictures, setPictures] = useState<PictureProps[]>([]);
    const history = useHistory();

    const { register, handleSubmit, watch, errors, triggerValidation } = useForm<HooksFormProps>();
    const [recoilTest, setRecoilTest] = useRecoilState(itemTitlesState);
    const { id } = useParams<ParamsIdProps>();
    const [itemId, setItemId] = useState(id);

    // [START makeItemData]
    const makeItemData = (value: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        const data = value.docs.reduce((result: ItemsProps[], current) => {
            if (current.data().category) {
                const element = result.find(p => p.title === current.data().category.title);
                if (element) {
                    //@ts-ignore
                    if (!element.items) element["items"] = [];
                    if (current.data().itemName) {
                        //@ts-ignore
                        element.items.push({
                            title: current.data().itemName,
                            id: current.id
                        });
                    }
                } else {
                    if (current.data().category) {
                        result.push({
                            title: current.data().category.title,
                            id: current.data().category.id
                        });
                    }
                    let _element = result.find(p => p.title === current.data().category.title);
                    if (_element) {
                        //@ts-ignore
                        if (!_element.items) _element["items"] = [];
                        if (current.data().itemName) {
                            //@ts-ignore
                            _element.items.push({
                                title: current.data().itemName,
                                id: current.id
                            });
                        }
                    }
                }
            }
            return result;
        }, []);
        return data;
    };
    // [END makeItemData]
    const [value, loading, error] = useCollection(db.collection("items"), {
        snapshotListenOptions: { includeMetadataChanges: true }
    });
    useEffect(() => {
        if (value) {
            //@ts-ignore
            setRecoilTest(makeItemData(value));
        }
    }, [value]);

    const onSubmit = async (data: HooksFormProps) => {
        let imageUrl = "";
        if (pictures) {
            try {
                let uuidv = uuidv4();
                let ref = storage.ref().child(uuidv);

                pictures.map(async ({ img }) => {
                    await ref
                        .put(img)
                        .then(async snapshot => snapshot.ref.getDownloadURL())
                        .then(async url => (imageUrl = await url))
                        .then(() => {
                            db.collection("messages").add({
                                ...data,
                                // itemId,
                                imageUrl,
                                createdAt: firebase.firestore.FieldValue.serverTimestamp()
                            });
                            history.push({
                                pathname: "/contents",
                                state: { ...data, imageUrl }
                            });
                        });
                });
            } catch (e) {
                console.log(e, "err");
            } finally {
            }
        }
    };

    useEffect(() => {
        if (pictures[0]) {
        }
    }, [pictures]);

    // functions
    const onDrop: ((files: File[], pictures: string[]) => void) | undefined = picture => {
        setPictures([...pictures, { img: picture[picture.length - 1], id: shortid.generate() }]);
    };
    const classes = useStyles();
    return (
        <>
            <main style={{ backgroundColor: "#fff" }}>
                <Grid item xs={12} sm={12} md={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <List>
                                <Accordion style={{ boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ minHeight: 48 }}
                                        classes={{ content: classes.AccordionSummaryText }}
                                    >
                                        <Typography className={classes.itemText} style={{ marginLeft: 15 }}>
                                            メッセージ送配信送信先
                                        </Typography>
                                        <HelpIcon style={{ marginLeft: 5, fontSize: 18, color: "#333333" }} />
                                    </AccordionSummary>
                                    <Divider style={{ height: 3, backgroundColor: "#8B726E", marginBottom: 33 }} />
                                    <AccordionDetails style={{ display: "block", padding: "0 16px" }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            style={{ marginTop: 30, color: "#262626" }}
                                        >
                                            <Grid item xs={6}>
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText
                                                        style={{ display: "flex" }}
                                                        className={classes.space}
                                                        primary={
                                                            <Typography
                                                                style={{
                                                                    marginRight: 50
                                                                }}
                                                            >
                                                                注文番号
                                                            </Typography>
                                                        }
                                                        secondary={<Typography>844-9538729-9008702</Typography>}
                                                    />
                                                </ListItem>
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText
                                                        style={{ display: "flex" }}
                                                        className={classes.space}
                                                        primary={
                                                            <Typography
                                                                style={{
                                                                    marginRight: 50
                                                                }}
                                                            >
                                                                対応状況
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <span>
                                                                <Typography style={{ color: "#262626" }}>
                                                                    近藤 加奈（タナカ オサム）
                                                                </Typography>
                                                                <Typography style={{ color: "#262626" }}>
                                                                    〒7886669
                                                                </Typography>
                                                                <Typography style={{ color: "#262626" }}>
                                                                    静岡県近藤市鈴木町鈴木3-1-6
                                                                </Typography>
                                                            </span>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText
                                                        style={{ display: "flex" }}
                                                        className={classes.space}
                                                        primary={
                                                            <Typography style={{ marginRight: 50 }}>
                                                                対応状況
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography>
                                                                <span className={classes.situation}>対応中</span>
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText
                                                        style={{ display: "flex" }}
                                                        primary={
                                                            <Typography style={{ marginRight: 50 }}>
                                                                注文金額
                                                            </Typography>
                                                        }
                                                        secondary={<Typography>¥10,000</Typography>}
                                                    />
                                                </ListItem>
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText
                                                        style={{ display: "flex" }}
                                                        primary={
                                                            <Typography style={{ marginRight: 35 }}>
                                                                購入商品名
                                                            </Typography>
                                                        }
                                                        secondary={<Typography>きゅうり5点</Typography>}
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </List>
                            <Accordion style={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{ minHeight: 48 }}
                                    classes={{ content: classes.AccordionSummaryText }}
                                >
                                    <Typography style={{ color: "#262626" }} className={classes.space}>
                                        メール内容
                                    </Typography>
                                </AccordionSummary>
                                <Divider style={{ height: 3, backgroundColor: "#8B726E", marginBottom: 33 }} />
                                <AccordionDetails style={{ display: "block", padding: "0 16px" }}>
                                    <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                        <Grid xs={2}>
                                            <div style={{ display: "flex", marginTop: 30 }}>
                                                <Typography className={classes.itemText} style={{ marginLeft: 15 }}>
                                                    メッセージ画像
                                                </Typography>
                                                <HelpIcon style={{ marginLeft: 5, fontSize: 18, color: "#333333" }} />
                                            </div>
                                        </Grid>
                                        <Grid xs={10} style={{ marginTop: 20 }}>
                                            <div
                                                style={{
                                                    border: "1px dashed #8B726E",
                                                    display: "flex",
                                                    borderRadius: 4
                                                }}
                                            >
                                                <CloudUploadIcon
                                                    style={{
                                                        fontSize: 70,
                                                        margin: "60px 6px 0 20px",
                                                        color: "#8B726E"
                                                    }}
                                                />
                                                <ImageUploader
                                                    onChange={onDrop}
                                                    withPreview={true}
                                                    buttonText={"ファイルを選択"}
                                                    name={"images"}
                                                    label={"画像をドラッグ&ドロップまたは"}
                                                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                                    maxFileSize={5242880}
                                                    withIcon={false}
                                                    fileContainerStyle={{
                                                        flexDirection: "row",
                                                        flexWrap: "wrap",
                                                        justifyContent: "initial",
                                                        margin: "40px 40px 40px 5px",
                                                        boxShadow: "none"
                                                    }}
                                                    buttonStyles={{
                                                        borderRadius: 4,
                                                        border: "solid 1px #595959",
                                                        color: "#595959",
                                                        background: "#fff",
                                                        padding: "13px 15px",
                                                        marginLeft: 12
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid xs={2} style={{ marginTop: 100 }}>
                                            <div style={{ display: "flex" }}>
                                                <Label text={"テンプレート"} />
                                                <HelpIcon style={{ fontSize: 18, marginTop: 10, color: "#333333" }} />
                                            </div>
                                        </Grid>
                                        <Grid xs={10} style={{ marginTop: 100 }}>
                                            <TextField size="small" variant="outlined" fullWidth>
                                                <Select native>
                                                    <option value="" />
                                                    <option value={10}>注文受付メール</option>
                                                    <option value={10}>注文受付メール</option>
                                                    <option value={10}>注文受付メール</option>
                                                </Select>
                                            </TextField>
                                        </Grid>
                                        <ThemeProvider theme={theme}>
                                            <Grid xs={2} style={{ marginTop: 10 }}>
                                                <Label isRequired={true} questionIcon={true} text={"件名"} />
                                            </Grid>
                                            <Grid xs={10}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    inputProps={{
                                                        ref: register({
                                                            required: <p className={"form-error"}>必須項目です</p>
                                                        })
                                                    }}
                                                    name={"title"}
                                                    rows={1}
                                                    multiline
                                                    margin="normal"
                                                    onChange={e => triggerValidation("title")}
                                                />

                                                {errors.title && errors.title.message}
                                            </Grid>
                                        </ThemeProvider>
                                        <Grid xs={2} style={{ marginTop: 10 }}>
                                            <Label isRequired={true} text={"本文"} />
                                        </Grid>
                                        <Grid xs={10} style={{ marginTop: 10 }}>
                                            <ThemeProvider theme={theme}>
                                                <OutlinedInput
                                                    fullWidth
                                                    inputProps={{
                                                        ref: register({
                                                            required: <p className={"form-error"}>必須項目です</p>
                                                        })
                                                    }}
                                                    name={"message"}
                                                    rows={15}
                                                    multiline
                                                    margin="none"
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Button
                            fullWidth
                            type="submit"
                            style={{
                                backgroundColor: "#FF8C3E",
                                color: "white",
                                marginTop: 40
                            }}
                        >
                            確認画面へ
                        </Button>
                    </form>
                </Grid>
            </main>
        </>
    );
};
const useStyles = makeStyles({
    inputFocused: {
        backgroundColor: "#00FF00"
    },
    itemText: {
        color: "#262626"
    },
    situation: {
        height: 30,
        width: 80,
        border: "1px solid #EEB128",
        borderRadius: 4,
        opacity: 1,
        color: "#EEB128",
        padding: "5px 10px"
    },
    space: {
        marginLeft: 15
    },
    AccordionSummaryText: {
        margin: "17px 0!important"
    }
});
const theme = createMuiTheme({
    palette: {},
    overrides: {
        // Style sheet name ⚛️
        MuiOutlinedInput: {
            // Name of the rule
            notchedOutline: {
                focused: {
                    outline: "2px solid red"
                }
                // Some CSS
            }
        },
        MuiButton: {
            text: {
                // Some CSS
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                color: "white",
                height: 48,
                padding: "0 30px",
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
            }
        }
    }
});

export default Delivery;
