import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "./AuthService";

const LoggedInRoute = ({ component: Component, redirect, ...rest }) => {
    const user = useContext(AuthContext);

    return (
        <Route {...rest} render={props => (user ? <Component initialPath={"/top"} /> : <Redirect to={"/login"} />)} />
    );
};

export default LoggedInRoute;
