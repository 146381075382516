import styled from 'styled-components'

const StyledLoginDialog = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    width: 360px;
    padding: 30px;
    form {
        width: 100%;
    }
    .input_errors {
        input {
            background-color: #FAF1F1;
            border: 1px solid #C04949; 
        }
    }
`

export default StyledLoginDialog;