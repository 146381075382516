import React from "react";

// pages
import Order from "../pages/Order";
import MessageCreate from "../pages/MessageCreate";
import Items from "../pages/Items";
import Delivery from "../pages/Delivery";
import Users from "../pages/Users";
import Drafts from "../pages/DraftsPage";
import Home from "../pages/Home";
import Prod from "../pages/RegisterProducts";

// icons
import HomeIcon from "@material-ui/icons/Home";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import EditIcon from "@material-ui/icons/Edit";
import TextsmsIcon from "@material-ui/icons/Textsms";
import GroupIcon from "@material-ui/icons/Group";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ListAltIcon from "@material-ui/icons/ListAlt";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const createRoute = (userId: string) => {
    const isBlue = userId === "zOFsDxTkxyNGTPGLOJffLt0vmtH2";
    return [
        {
            path: "/top",
            component: Home,
            name: "ホーム",
            icon: <HomeIcon />
        },
        {
            path: "/order",
            component: Order,
            name: "注文一覧",
            icon: <ShoppingCartIcon />
        },
        {
            path: "/new",
            component: isBlue ? Prod : MessageCreate,
            name: "商品登録",
            icon: <EditIcon />
        },
        {
            path: "/delivery",
            component: Delivery,
            name: "メッセージ配信",
            icon: <TextsmsIcon />
        },
        {
            path: "/users",
            component: Users,
            name: "顧客詳細設定",
            icon: <GroupIcon />
        },
        {
            path: "/drafts",
            component: Drafts,
            name: "下書き一覧",
            icon: <ListAltIcon />
        },
        {
            path: "/Items",
            component: Items,
            name: "受付中の商品",
            icon: <FastfoodIcon />
        },
        {
            path: "/info",
            component: Items,
            name: "情報",
            icon: <InfoIcon />
        },
        {
            path: "/setting",
            component: Items,
            name: "設定",
            icon: <SettingsIcon />
        }
    ] as const;
};

export const sidebarRoutes = [
    {
        path: "/top",
        component: Home,
        name: "ホーム",
        icon: <HomeIcon />
    },
    {
        path: "/order",
        component: Order,
        name: "注文一覧",
        icon: <ShoppingCartIcon />
    },
    {
        path: "/new",
        component: Prod,
        name: "商品登録",
        icon: <EditIcon />
    },
    {
        path: "/delivery",
        component: Delivery,
        name: "メッセージ配信",
        icon: <TextsmsIcon />
    },
    {
        path: "/users",
        component: Users,
        name: "顧客詳細設定",
        icon: <GroupIcon />
    },
    {
        path: "/drafts",
        component: Drafts,
        name: "下書き一覧",
        icon: <ListAltIcon />
    },
    {
        path: "/Items",
        component: Items,
        name: "受付中の商品",
        icon: <FastfoodIcon />
    },
    {
        path: "/info",
        component: Items,
        name: "情報",
        icon: <InfoIcon />
    },
    {
        path: "/setting",
        component: Items,
        name: "設定",
        icon: <SettingsIcon />
    }
] as const;
