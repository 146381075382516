import React, {FC} from "react"

import {
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import {
  TreeItem,
} from '@material-ui/lab';

type CategoryProps = {
  id:string;
  name:string;
  children?: CategoryProps[];
}



const getChildById = (node:CategoryProps, id:string) => {
  let array:string[] = [];

  const getAllChild = (nodes:CategoryProps | null)=> {
      if (nodes === null) {
          return []
      }
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
          nodes.children.forEach(node => {
              array = [...array, ...getAllChild(node)];
              array = array.filter((v, i) => array.indexOf(v) === i);
          });
      }
      return array;
  }

  const getNodeById = (nodes:CategoryProps, id:string) => {
      if (nodes.id === id) {
          return nodes;
      } else if (Array.isArray(nodes.children)) {
          let result = null;
          nodes.children.forEach(node => {
              if (!!getNodeById(node, id)) {
                  result = getNodeById(node, id);
              }
          });
          return result;
      } else {
          return null;
      }
  }
  return getAllChild(getNodeById(node, id));
}

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>,
  nodes: CategoryProps,
  selected: string[]
}

const RenderTree:FC<Props> = ({nodes, setSelected,selected}) => {
  const getOnChange = (checked:boolean, nodes:CategoryProps) => {
      const allNode = getChildById(nodes, nodes.id);
      let array = checked
          ? [...selected, ...allNode]
          : selected.filter(value => !allNode.includes(value));

      array = array.filter((v, i) => array.indexOf(v) === i);

      setSelected(array);
  }

  return (
      <TreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={selected.some(item => item === nodes.id)}
                          onChange={event =>
                              getOnChange(event.currentTarget.checked, nodes)
                          }
                          onClick={e => e.stopPropagation()} //チェックボックスを押してもTreeが動かないように制限している
                      />
                  }
                  label={<div style={{ color: '#262626', fontSize: 14 }}>{nodes.name}</div>}
                  key={nodes.id}
              />
          }
      >
          {Array.isArray(nodes.children) && nodes.children.map(node => (
          <RenderTree nodes={node} selected={selected} setSelected={setSelected}/>
          ))}
      </TreeItem>
  )
};

export default RenderTree;