 export type RowsProps = {
    id: number;
    img: string;
    name: string;
    code: string;
    price: number;
    stock: number;
    open: boolean;
    period: string;
}
 
 const createData = (
  id: number,
  img: string,
  name: string,
  code: string,
  price: number,
  stock: number,
  open: boolean,
  period: string
  ) => (
  { id, img, name, code, price, stock, open, period }
  );

  export const rows:RowsProps[] = [
    createData(
        34,
        'img',
        '徳用きゅうり',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        17,
        'img',
        '徳用トマト',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        49,
        'img',
        '徳用にんじん',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        94,
        'img',
        '徳用玉ねぎ',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        26,
        'img',
        '徳用かぼちゃ',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        95,
        'img',
        '徳用桃',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        72,
        'img',
        '徳用スイカ',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        65,
        'img',
        '徳用ほうれん草',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        73,
        'img',
        '徳用ねぎ',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        67,
        'img',
        '徳用じゃがいも',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        32,
        'img',
        '徳用なす',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        53,
        'img',
        '徳用オクラ',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
    createData(
        43,
        'img',
        '徳用大根',
        'fork-01',
        12000,
        2,
        true,
        '06月29日 08時〜06月29日 08時'
    ),
]

