import React, {FC, useState} from "react"
// components
import RenderTree from "../molecules/RenderTree"
import AddDialog from "./AddDialog"
// material ui
import {makeStyles} from "@material-ui/styles"
import {
  Button,
  Grid,
  OutlinedInput
} from "@material-ui/core"
import {
  TreeView,
} from '@material-ui/lab';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons"

const useStyles = makeStyles({
  root: {
      height: 299,
      flexGrow: 1,
      border: 'solid 1px #D1D1D1',
      borderRadius: 4,
      marginTop: 10,
      overflow: 'scroll',
  },
  inputPriceContainer: {
      padding: '11px 45px',
  },
  button: {
    background: '#fff',
    border: 'solid 1px #595959',
    color: '#595959',
    fontWeight: 'bold',
    boxShadow: 'none',
    marginTop: 10,
  },
  searchIcon: {
    position: 'absolute',
    fontSize: 30,
    backgroundColor: '#EDE8E5',
    height: '100%',
    borderRight: 'solid 1px #D1D1D1',
    padding: 10,
    width: '40px',
  }
});

type CategoryProps = {
  id:string;
  name:string;
  children?: CategoryProps[];
}

type Props = {
  categoryData: CategoryProps[],
  addCategory: (value: string)=>void,
  setSelected: React.Dispatch<React.SetStateAction<string[]>>,
  selected: string[]
}

const CategoryArea:FC<Props> = ({categoryData, addCategory, setSelected, selected})=>{
  const [inputVisible, setInputVisible] = useState(false)
  const [value, setValue] = useState("")
  const classes = useStyles()

  const handleAddButtonClick = ()=>{
    // TODO validation
    addCategory(value);
    setValue("")
  }
  return (
      <Grid container alignItems='center' style={{ marginBottom: 10 }}>
        <Grid item xs={12} style={{ position: 'relative' }}>
            <SearchIcon className={classes.searchIcon} />
            <OutlinedInput
              fullWidth
              placeholder='検索ワード'
              margin="dense"
              classes={{input:classes.inputPriceContainer}}
            />
        </Grid>
        <Grid item xs={12}>
            <TreeView
                className={classes.root}
                defaultExpanded={["0", "3", "4"]}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {categoryData.map(el => (
                  <RenderTree nodes={el} selected={selected} setSelected={setSelected} />
                ))}
            </TreeView>
        </Grid>
        <Grid item xs={12}>
            <Button
                fullWidth
                className={classes.button}
                onClick={() => setInputVisible(!inputVisible)}
            >
                カテゴリの追加・編集
            </Button>
            <AddDialog
                value={value}
                placeholder='追加するカテゴリ'
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {setValue(e.target.value)}}
                isOpen={inputVisible}
                addClick={handleAddButtonClick}
            />
        </Grid>
    </Grid>
  )
}

export default CategoryArea;