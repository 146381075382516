export const customerGroupedProfitLabel = [
    {
        text:"未分類グループ",
				name:"mibunrui",
				deliveryFee: 0,
				salesPrice: 0
    },
    {
        text:"●●グループ",
				name:"mibunrui1",
				deliveryFee: 0,
				salesPrice: 0
    },
    {
        text:"●●グループ",
				name:"mibunrui2",
				deliveryFee: 0,
				salesPrice: 0
    }
]
