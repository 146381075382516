
type DescendingComparator = <T, K extends keyof T>(a: T, b: T, orderBy: K)=> 1 | -1 | 0;

const descendingComparator: DescendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

type GetComparator = <T, K extends keyof T>(order:'asc' | 'desc', orderBy: K) => (a: T,b: T)=> 1 | -1 | 0;

const getComparator: GetComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        // 以下の理由により「as」は問題ないこととする。
        // descendingComparator関数の返り値に対して「-」をつけることによって
        // TypeScriptがdescendingComparator関数の返り値の型をnumberと推論してしまうため「as」で補完した。
        // numberと推論してしまうことによって型GetComparatorに合わなくなってしまうため「as」の補完が必要である。
        : (a, b) => -descendingComparator(a, b, orderBy) as 1 | -1 | 0;
}

type StableSort = <T>(array:T[], comparator: (a:T, b:T)=> 1 | -1 | 0)=> T[]

const stableSort: StableSort = (array,comparator) => {
    const stabilizedThis = array.map((element,index) => ({element, index}));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.element, b.element);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map(({element}) => element);
}

type Sort = <T, K extends keyof T>(array: T[], order:'asc' | 'desc', orderBy: K)=> T[]

const sort:Sort = (array, order, orderBy)=>{
  return stableSort(array, getComparator(order, orderBy))
}

export default sort;