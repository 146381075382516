import React, {FC} from "react";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@material-ui/icons/Help';

type Props = {
  text: string;
  isRequired?: boolean;
  questionIcon?: boolean;
  align?: "left" | "inherit" | "center" | "right" | "justify";
  fontWeight?: "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "bold" | "normal" | (number & unknown) | "bolder" | "lighter";
}

const Label:FC<Props> = ({
  text,
  isRequired,
  questionIcon,
  align="left",
  fontWeight="normal"
}) =>(
  <div style={{display:'flex'}}>
    <Typography
      align={align}
      variant={"body1"}
      style={{
        margin:"10px",
        fontWeight:fontWeight,
        fontSize:14,
        color:'#262626'
      }}>
        {text}
        {questionIcon && <HelpIcon style={{fontSize:18, color:'#333333',marginLeft: "6px"}}/>}
          {isRequired &&
          <span
            style={{
              backgroundColor:"#FF8C3E",
              color:"white",
              height:36,
              width:20,
              marginLeft: "8px",
              fontSize: "12px",
              padding: "0 4px",
              borderRadius:4,
              opacity: 1
            }}>
            必須
          </span>
          }
    </Typography>
  </div>
)

export default Label;
