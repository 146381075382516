// register form
import React, { useContext, useEffect, useState, FC } from "react";
import { useForm } from "react-hook-form";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { db, storage } from "../../assets/Firebase";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
// recoil
import { useSetRecoilState } from "recoil";
import { activeSidebarItem } from "../../recoil/rootData";
// conponents
import { AuthContext } from "../AuthService";
import AddDialog from "../organisms/AddDialog";
import CategoryArea from "../organisms/CategoryArea";
import FreeAreaTemplate from "../templates/FreeAreaTemplate";
import ShopMemoTemplate from "../templates/ShopMemoTemplate";
import BasicFormTemplate from "../templates/BasicFormTemplate";
// material
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import FlagIcon from "@material-ui/icons/Flag";
import CachedIcon from "@material-ui/icons/Cached";
import PersonIcon from "@material-ui/icons/Person";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CardContent from "@material-ui/core/CardContent";

import { customerGroupedProfitLabel } from "../../inputData";
import { ItemHookFormValue, FirestoreItem } from "../../type";

const useStyles = makeStyles({
    AccordionSummaryText: {
        margin: "17px 0!important"
    }
});

type CategoryProps = {
    id: string;
    name: string;
    children?: CategoryProps[];
};

type PictureProps = {
    img: File;
    id: string;
};

const MessageCreate: FC = () => {
    // [HOOKS]
    const [startAt, setStartAt] = useState(new Date());
    const [orderStartAt, setOrderStartAt] = useState(new Date());
    const [endAt, setEndAt] = useState(new Date());
    const [orderEndAt, setOrderEndAt] = useState(new Date());

    const [open, setOpen] = React.useState(false);

    const [collapseSalesPrice, setCollapseSalesPrice] = useState<"same" | "each">("same");
    const [isDraft, setIsDraft] = useState(false);
    const [productSpiecies, setProductSpiecies] = useState("normal");
    const [tagsAddOpen, setTagsAddOpen] = useState(false);
    const [itemId, setItemId] = useState("");
    const setActivePath = useSetRecoilState(activeSidebarItem);
    const [tags, setTags] = useState(["限定品", "おすすめ商品", "新商品"]);
    const [newTag, setNewTag] = useState("");
    const [selected, setSelected] = useState<string[]>([]);

    const history = useHistory();

    // firestore
    const [pictures, setPictures] = useState<PictureProps[]>([]);
    const [category, setCategory] = useState("");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [categories, setCategories] = useState<firebase.firestore.DocumentData[]>([]);
    const [subCategoryId, setSubCategoryId] = useState();
    const { register, handleSubmit, watch, errors, getValues, control } = useForm<ItemHookFormValue>();
    const dailyNecessities = watch("dailyNecessities");
    const user = useContext(AuthContext);
    const [value, loading, error] = useCollection(
        firebase
            .firestore()
            .collection("category")
            .where("userId", "==", user ? user.uid : ""),
        { snapshotListenOptions: { includeMetadataChanges: true } }
    );
    //カテゴリー
    const [categoryData, setCategoryData] = useState<CategoryProps[]>([
        {
            id: "0",
            name: "カテゴリA",
            children: [
                {
                    id: "1",
                    name: "カテゴリA-a"
                },
                {
                    id: "2",
                    name: "カテゴリA-b"
                },
                {
                    id: "3",
                    name: "カテゴリA-c",
                    children: [
                        {
                            id: "4",
                            name: "カテゴリA-c-2"
                        }
                    ]
                }
            ]
        },
        {
            id: "5",
            name: "カテゴリB",
            children: [
                {
                    id: "6",
                    name: "カテゴリB-a"
                }
            ]
        }
    ]);

    // [START handleClick]
    const handleClick = (id: string) => {
        setActivePath("/delivery");
        history.push(`/delivery/${id}`);
    };
    // [END handleClick]

    const handleClose = () => {
        setOpen(false);
    };
    const handleDraftClose = () => {
        setIsDraft(false);
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setProductSpiecies(e.target.value as string);
    };
    // {START functions}

    // [START handleChange]
    const handleChange = (value: "same" | "each") => {
      setCollapseSalesPrice(value);
    };
    // [END handleChange]
    const onDrop: ((files: File[], pictures: string[]) => void) | undefined = picture => {
        setPictures([...pictures, { img: picture[picture.length - 1], id: shortid.generate() }]);
    };

    const addCategory = (value: string) => {
        // 重複バリデーションなし
        setCategoryData([
            ...categoryData,
            {
                id: String(categoryData.length + 1),
                name: value,
                children: []
            }
        ]);
    };

    const addTag = () => {
        if (!tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag("");
        }
    };

    const onSubmit = async (data: ItemHookFormValue) => {
        setPictures([]);
        setOpen(true);
        const imageUrls = await getImageUrls(pictures);
        addItem(imageUrls, data);
    };
    const handleDraftClick = async (data: ItemHookFormValue) => {
        setPictures([]);
        setIsDraft(true);
        const imageUrls = await getImageUrls(pictures);
        saveDraft(imageUrls, data);
    };
    const getImageUrls = async (pictures: PictureProps[]) => {
        return await Promise.all(
            pictures.map(async ({ img }) => {
                // file形式からfirebaseのURLに変換した配列を返す.
                let uuidv = uuidv4();
                let ref = storage.ref().child(uuidv);
                return ref.put(img).then(async snapshot => {
                    return await snapshot.ref.getDownloadURL();
                });
            })
        );
    };
    const addItem = (imageUrls: string[], data: ItemHookFormValue) => {
        let categoryTitle = "";
        selected.forEach(num => {
            categoryData.forEach(el => {
                if (el.id === num) categoryTitle = el.name;
            });
        });
        const category ={
					createdAt: new Date(),
					title: categoryTitle,
					userId: user?.uid ? user.uid : "user"
				}
				const gruopOptions = customerGroupedProfitLabel.map((group)=>{
					return {
						groupName: group.name,
						deliveryFee: group.deliveryFee,
						salesPrice: group.salesPrice
					}
				})

        const item: FirestoreItem = {
            amount: parseInt(data.amount),
            description: data.description,
            freeArea: data.freeArea,
            itemName: data.itemName,
            memo: data.memo,
            kind: data.kind,
            createdAt: new Date(),
            salesPeriodDate: data.salesPeriodDate,
            salesPriceWithoutTax: parseInt(data.salesPriceWithoutTax),
            taxIncludePrice: parseInt(data.taxIncludePrice),
            imageUrls,
            userId: user?.uid || "user",
            updatedAt: new Date(),
            priceStartAt: startAt,
            priceEndAt: endAt,
            orderStartAt,
            orderEndAt,
            tags,
            category,
            gruopOptions: collapseSalesPrice === "each" ? gruopOptions : null
        };
        db.collection("items")
            .add(item)
            .then(item => item.get().then(doc => setItemId(doc.id)))
            .catch(error => console.log(error));
    };

    const saveDraft = (imageUrls: string[], data: ItemHookFormValue) => {
        const d = new Date();
        const DateTimeFormat = "YYYY/MM/DD hh:mi:ss";
        let created = DateTimeFormat.replace(/YYYY/g, String(d.getFullYear()))
            .replace(/MM/g, ("0" + (d.getMonth() + 1)).slice(-2))
            .replace(/DD/g, ("0" + d.getDate()).slice(-2))
            .replace(/hh/g, ("0" + d.getHours()).slice(-2))
            .replace(/mi/g, ("0" + d.getMinutes()).slice(-2))
            .replace(/ss/g, ("0" + d.getSeconds()).slice(-2));
        db.collection("/drafts").add({
            ...data,
            category: selected,
            imageUrls,
            userId: user?.uid || null,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            tag: tags,
            created: created, // 新規作成の場合不要にしたい
            lastEdited: created
        });
		};

    // [START useEffect]
    useEffect(() => {
        if (!user) {
            history.push("/");
        }
        if (!error && !loading && value) {
            let _categories: firebase.firestore.DocumentData[] = [];
            value.docs.map(doc => {
                _categories.push(doc.data());
            });
            setCategories(_categories);
        }
    }, [value, category, subCategoryId, collapseSalesPrice]);

    const deletePicture = (id: string) => {
        setPictures(pictures.filter(picture => picture.id !== id));
    };
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={12}>
            <Card>
                <CardHeader color="rose" icon>
                    <h4>メッセージ登録</h4>
                </CardHeader>
                <CardContent>
                    <p style={{ fontSize: 20, marginBottom: 62 }}>
                        商品登録
                        <span style={{ fontSize: 16, marginLeft: 14 }}>商品管理</span>
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={8}>
                                <BasicFormTemplate
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    pictures={pictures}
                                    deletePicture={deletePicture}
                                    onDrop={onDrop}
                                    dailyNecessities={dailyNecessities}
                                    setOrderEndAt={setOrderEndAt}
                                    setOrderStartAt={setOrderStartAt}
                                    orderEndAt={orderEndAt}
                                    orderStartAt={orderStartAt}
                                    startAt={startAt}
                                    endAt={endAt}
                                    setStartAt={setStartAt}
                                    setEndAt={setEndAt}
                                    salesPriceWithoutTax={watch(`salesPriceWithoutTax`)}
                                    collapseSalesPrice={watch("group")}
                                    collapseDeliveryFee={watch("group")}
                                />
                                <FreeAreaTemplate register={register} errorMessage={errors.freeArea} />
                                {/* Dialog  */}

                                <Button
                                    fullWidth
                                    style={{ backgroundColor: "#FF8C3E", fontWeight: "bold", fontSize: 14 }}
                                    type={"submit"}
                                    variant="contained"
                                    color="primary"
                                >
                                    掲載を開始する
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    fullWidth
                                    PaperProps={{
                                        style: {
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "80vh",
                                            maxHeight: "80vh"
                                        }
                                    }}
                                >
                                    <Grid container direction="column" justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography align={"center"} variant={"h5"} component={"h3"}>
                                                商品を掲載しました。
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            align={"center"}
                                            variant={"h4"}
                                            style={{
                                                color: "#DD5F2A"
                                            }}
                                            component={"h4"}
                                        >
                                            顧客に自動に配信されません。
                                        </Typography>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{
                                                    color: "white",
                                                    width: "356px",
                                                    fontSize: "24px",
                                                    margin: "0 auto",
                                                    marginTop: "20px",
                                                    backgroundColor: "#DD5F2A"
                                                }}
                                                onClick={() => {
                                                    handleClick(itemId);
                                                }}
                                            >
                                                メッセージ配信へ
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{
                                                    width: "356px",
                                                    fontSize: "24px",
                                                    margin: "0 auto",
                                                    marginTop: "20px",
                                                    backgroundColor: "#BFBFBF"
                                                }}
                                                onClick={() => setOpen(false)}
                                            >
                                                閉じる
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                                <Button
                                    fullWidth
                                    type={"button"}
                                    onClick={() => {
                                        handleDraftClick(getValues({ nest: true }));
                                    }}
                                    style={{
                                        color: "#262626",
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        backgroundColor: "#EDE8E5"
                                    }}
                                >
                                    下書きとして保存する
                                </Button>
                                <Dialog
                                    open={isDraft}
                                    onClose={handleDraftClose}
                                    fullWidth
                                    PaperProps={{
                                        style: {
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "80vh",
                                            maxHeight: "80vh"
                                        }
                                    }}
                                >
                                    <Grid container direction="column" justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography align={"center"} variant={"h5"} component={"h3"}>
                                                下書きを保存しました。
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{
                                                    width: "356px",
                                                    fontSize: "24px",
                                                    margin: "0 auto",
                                                    marginTop: "20px",
                                                    backgroundColor: "#BFBFBF"
                                                }}
                                                onClick={() => setIsDraft(false)}
                                            >
                                                閉じる
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                            </Grid>
                            <Grid item xs={4}>
                                <Accordion style={{ boxShadow: "none", marginLeft: 15 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ minHeight: 48 }}
                                        classes={{ content: classes.AccordionSummaryText }}
                                    >
                                        <Typography>カテゴリ</Typography>
                                    </AccordionSummary>
                                    <Divider style={{ height: 3, backgroundColor: "#8B726E", marginBottom: 22 }} />
                                    <AccordionDetails style={{ display: "block", padding: "0 15px" }}>
                                        <CategoryArea
                                            categoryData={categoryData}
                                            addCategory={addCategory}
                                            setSelected={setSelected}
                                            selected={selected}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: "none", marginLeft: 15 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ minHeight: 48 }}
                                        classes={{ content: classes.AccordionSummaryText }}
                                    >
                                        <Typography>タグ</Typography>
                                    </AccordionSummary>
                                    <Divider style={{ height: 3, backgroundColor: "#8B726E", marginBottom: 22 }} />
                                    <AccordionDetails style={{ display: "block", padding: "0 15px" }}>
                                        <Grid container alignItems="center" style={{ marginBottom: 10 }}>
                                            <Grid item xs={12} style={{ marginBottom: 22 }}>
                                                <Typography style={{ fontSize: 13, margin: "0" }}>
                                                    <IndeterminateCheckBoxIcon
                                                        style={{
                                                            verticalAlign: "top",
                                                            color: "#333333",
                                                            fontSize: 18,
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                    <span>タグ登録</span>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    backgroundColor: "#EDE8E5",
                                                    minHeight: 130,
                                                    padding: "11px 15px"
                                                }}
                                            >
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {tags.map(item => (
                                                        <Button
                                                            style={{
                                                                background: "inherit",
                                                                border: "solid 1px #595959",
                                                                color: "#595959",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                padding: "11px 13px",
                                                                marginRight: 10
                                                            }}
                                                            onClick={() => {
                                                                if (!selectedTags.includes(item)) {
                                                                    setSelectedTags([...selectedTags, item]);
                                                                }
                                                            }}
                                                        >
                                                            {item}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    style={{
                                                        background: "#fff",
                                                        border: "solid 1px #595959",
                                                        color: "#595959",
                                                        fontWeight: "bold",
                                                        boxShadow: "none",
                                                        marginTop: 10
                                                    }}
                                                    onClick={() => setTagsAddOpen(!tagsAddOpen)}
                                                >
                                                    タグの追加・編集
                                                </Button>
                                                <AddDialog
                                                    value={newTag}
                                                    placeholder="追加するタグ"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        setNewTag(e.target.value)
                                                    }
                                                    isOpen={tagsAddOpen}
                                                    addClick={addTag}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: "none", marginLeft: 15 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ minHeight: 48 }}
                                        classes={{ content: classes.AccordionSummaryText }}
                                    >
                                        <Typography>登録日・更新日</Typography>
                                    </AccordionSummary>
                                    <Divider style={{ height: 3, backgroundColor: "#8B726E", marginBottom: 22 }} />
                                    <AccordionDetails style={{ display: "block", padding: "0 15px" }}>
                                        <Grid container alignItems="center" style={{ marginBottom: 10 }}>
                                            <Grid item xs={12} alignItems="center">
                                                <Typography style={{ color: "#262626" }}>
                                                    <FlagIcon style={{ verticalAlign: "text-top" }} />
                                                    登録日：2017/11/09
                                                </Typography>
                                                <Typography style={{ color: "#262626" }}>
                                                    <CachedIcon style={{ verticalAlign: "text-top" }} />
                                                    最終更新日：2017/11/12
                                                </Typography>
                                                <Typography style={{ color: "#262626" }}>
                                                    <PersonIcon style={{ verticalAlign: "text-top" }} />
                                                    最終更新者：user_name
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <ShopMemoTemplate register={register} />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    );
};

// theme
//@ts-ignore
const styles = theme => ({
    root: {
        "& $notchedOutline": {
            borderWidth: 1
        },
        "&:hover $notchedOutline": {
            borderWidth: 1
        },
        "&$focused $notchedOutline": {
            borderWidth: 1,
            borderColor: "red"
        }
    },
    focused: {},
    notchedOutline: {}
});
export default withStyles(styles)(MessageCreate);
