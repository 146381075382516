import React, { useContext, useEffect, useState, FC, ChangeEvent } from "react";
import { groupedUsersState, usersState } from "../../recoil/rootData";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthContext } from "../AuthService";
import firebase, { db, storage } from "../../assets/Firebase";
import { useCollection, useCollectionData } from "react-firebase-hooks/firestore";

// material
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
    dialogPaper: {
        minHeight: "80vh",
        maxHeight: "80vh"
    }
});

type DialogExampleProps = {
    classes?: any;
    title: string;
    text: string;
    pictures?: any;
    itemId: string;
    btnTxt?: string;
    imageUrl: string;
};

const DialogExample: FC<DialogExampleProps> = ({ classes, title, text, pictures, itemId, btnTxt, imageUrl }) => {
    const [value1, loading, error] = useCollection(firebase.firestore().collection(`member/`), {});

    const [checked, setChecked] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState();

    const [values, error1, loading1] = useCollectionData(db.collection("member"));
    const [userRecoilState, setUserRecoilState] = useRecoilState(usersState);
    const groupedUser = useRecoilValue(groupedUsersState);

    const user = useContext(AuthContext);

    let imageUrls = [];
    const handleToggle = (value: string) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    // [START handleClickOpen]
    const handleClickOpen = () => {
        setOpen(true);
        if (pictures) {
            for (let i = 0; i < pictures.length; i++) {
                let ref = storage.ref().child(`${Date.now()}`);
                let picture = pictures[i][0];
                ref.put(picture).then(snapshot => {
                    snapshot.ref.getDownloadURL().then(url => {
                        imageUrls.push(url);
                        setUrl(url);
                    });
                });
            }
        }
    };
    const getUrlByUserId = (userId: string | undefined) => {
        switch (userId) {
            case "BKgRwRTKfiSvvq3TtSuxE8q4Ioj1":
                return "https://tranquil-brook-61378.herokuapp.com/api";
            case "XTiMYjQyLRaClwqpAOzAiGj7toN2":
                return "https://mighty-sierra-37895.herokuapp.com/api";
            default:
                return "https://shrouded-lake-11999.herokuapp.com/api";
        }
    };
    const getToUrlByUserId = (userId: string | undefined) => {
        switch (userId) {
            case "XTiMYjQyLRaClwqpAOzAiGj7toN2":
                return "https://liff.line.me/1654037541-y1gdbggb";
            default:
                return "";
        }
    };

    // [END handleClickOpen]
    const btnClicked = () => {
        let toUrl = getToUrlByUserId(user?.uid);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /*
             * TODO: メッセージのタイプなどもここで決定できるように
             *  Golangの設定も変える必要がある
             * */
            body: JSON.stringify({
                to: checked,
                title,
                toUrl,
                text,
                itemId: itemId,
                imageUrl: imageUrl
            })
        };
        let url = getUrlByUserId(user?.uid);
        fetch(url, requestOptions)
            .then(response => {
                response.json();
            })
            .catch(err => {});
    };
    const handleAllCheck = (event: ChangeEvent<HTMLInputElement>) => {};
    const handleClose = () => {
        setChecked([]);
        setOpen(false);
    };
    useEffect(() => {
        if (values && !loading1) {
            //@ts-ignore
            setUserRecoilState(values);
        }
    }, [url, checked, values]);
    return (
        <>
            <Button
                fullWidth
                style={{ width: "100%", backgroundColor: "#DD5F2A" }}
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                {btnTxt ? btnTxt : "掲載を開始する"}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth classes={{ paperFullWidth: classes.dialogPaper }}>
                <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto", textAlign: "center" }}>
                    配信先を選んでください
                </DialogTitle>
                <Typography>
                    <Checkbox tabIndex={-1} disableRipple />
                    全ての未配信ユーザー
                </Typography>

                <Typography>
                    <Checkbox tabIndex={-1} disableRipple onChange={handleAllCheck} />
                    全てのユーザー
                </Typography>
                <Divider />

                <List>
                    {groupedUser && (
                        <>
                            {Object.keys(groupedUser).map(group => (
                                <>
                                    <Typography>
                                        <Checkbox tabIndex={-1} disableRipple />
                                        {group === "other" ? "未分類グループ" : group}
                                    </Typography>
                                    {//@ts-ignore
                                    groupedUser[group].map((user: { [param: string]: string }) => (
                                        <ListItem button /* TODO onlick追加onClick={}*/>
                                            <Checkbox
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={e => handleToggle(user.userId)}
                                            />
                                            <ListItemAvatar>
                                                <Avatar src={user.profileUrl} />
                                            </ListItemAvatar>
                                            <ListItemText primary={user.userName} />
                                        </ListItem>
                                    ))}
                                </>
                            ))}
                        </>
                    )}

                    <Divider />
                </List>
                <Button style={{ margin: 0 }} onClick={btnClicked}>
                    共有
                </Button>
            </Dialog>
        </>
    );
};
export default withStyles(styles)(DialogExample);
