import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Dialog
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { CloudDownload } from "@material-ui/icons";
import ImageUploader from "react-images-upload";
import { FormContainer, FormInLabel } from "../molecules/RegisterFormGroup";
import shortid from "shortid";
import { db, storage } from "../../assets/Firebase";
import {AuthContext} from "../AuthService"
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#D3D3D3"
    },
    selectInput: {
        padding: "11px!important"
    },
    header: {
        height: 60,
        lineHeight: "60px",
        borderBottom: "2px solid",
        fontWeight: "bold"
    },
    uploaderWrapper: {
        border: "1px dashed #8B726E",
        display: "flex",
        borderRadius: 4,
        width: 700,
        marginTop: "50px",
        backgroundColor: "#FFFFFF"
    },
    uploadIcon: {
        fontSize: 100,
        margin: "50px 6px 0 60px",
        color: "#8B726E"
    },
    input: {
        maxWidth: 600,
        width: "50vw",
        textAlign: "right",
        paddingRight: 15
    },
    postingStart: {
        backgroundColor: "#FF8C3E",
        fontWeight: "bold",
        fontSize: 14,
        color: "#000000",
        height: 60,
        marginTop: 50
    },
    draftSaving: {
        color: "#000000",
        fontWeight: "bold",
        fontSize: 14,
        backgroundColor: "#778899",
        height: 60,
        margin: "20px 0"
    },

    // 以下のスタイルはImageUpload.tsxから持ってきた
    hoverBg: {
        position: "absolute",
        width: "100%",
        height: 170,
        textAlign: "right",
        backgroundColor: "rgba(0,0,0,0.5)",
        opacity: 0,
        "&:hover": {
            opacity: 100
        }
    },
    imgBox: {
        width: 170,
        height: 170,
        backgroundColor: "#F2F2F2",
        display: "flex",
        alignItems: "center",
        // marginTopは追加した
        marginTop: 50,
        marginRight: 20,
        position: "relative"
    },
    close45: {
        position: "absolute",
        width: 17,
        height: 4,
        backgroundColor: "#442D29",
        transform: "rotate(45deg)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto"
    },
    close135: {
        position: "absolute",
        width: 17,
        height: 4,
        backgroundColor: "#442D29",
        transform: "rotate(135deg)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto"
    }
});

type PictureProps = {
    img: File;
    id: string;
};

const RegisterProducts = () => {
    const user = useContext(AuthContext)
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    /* タイプ */
    const [type, setType] = useState<"productSales" | "service" | "coupon">("productSales");
    /* カテゴリー */
    const [category, setCategory] = useState<"生活用品" | "簡単なサービス" | "近所の美味しい店">("生活用品");
    console.log(category)
    /* 在庫数 */
    const [amount, setAmount] = useState("10");
    /* 商品名 */
    const [itemName, setItemName] = useState("");
    /* 商品説明 */
    const [description, setDescription] = useState("");
    /* 配送方法 */
    const [deliveryMethod, setDeliveryMethod] = useState<"posting" | "delivery" | "pickUp">("posting");
    /* 送料負担 */
    const [sippingCost, setSippingCost] = useState<"includeShippingFee" | "cashOnDelivery">("includeShippingFee");
    /* 発送までの日数 */
    const [deliveryDays, setDeliveryDays] = useState<"oneTwo" | "twoThree" | "fourSeven">("oneTwo");
    /* 税込価格 */
    const [taxIncludePrice, setTaxIncludePrice] = useState("");

    // ↓元のImageUpload.tsxが他のところで使われていたので、そこを変えないように持ってきました
    const [pictures, setPictures] = React.useState<PictureProps[]>([]);
    const onDrop: ((files: File[], pictures: string[]) => void) | undefined = picture => {
        setPictures([...pictures, { img: picture[picture.length - 1], id: shortid.generate() }]);
    };
    const deletePicture = (id: string) => {
        setPictures(pictures.filter(picture => picture.id !== id));
    };
    // ↑ここまで
    const getImageUrls = async (pictures: PictureProps[]) => {
        return await Promise.all(
            pictures.map(async ({ img }) => {
                // file形式からfirebaseのURLに変換した配列を返す.
                let uuidv = uuidv4();
                let ref = storage.ref().child(uuidv);
                return ref.put(img).then(async snapshot => {
                    return await snapshot.ref.getDownloadURL();
                });
            })
        );
    };

    const addItem = (imageUrls: string[]) => {
        db.collection("items")
            .add({
                type,
                category: {
                    createdAt: new Date(),
                    title: category,
                    userId: user?.uid || "user"
                },
                amount,
                itemName,
                description,
                deliveryMethod,
                sippingCost,
                deliveryDays,
                taxIncludePrice,
                imageUrls,
                userId: user?.uid,
                createdAt: new Date()
            })
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setOpen(true);
        const imageUrls = await getImageUrls(pictures);
        addItem(imageUrls);
        setPictures([]);
    };

    const handleChange = (
        e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        setType(e.target.value as "productSales" | "service" | "coupon");
    };
    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            <Grid xs={12}>
                <Typography align="center" className={classes.header}>
                    商品登録
                </Typography>
            </Grid>

            <FormContainer notInput>
                {/*------------------ ↓ここもImageUpload.tsxから ----------------------*/}
                <Box display="flex">
                    {pictures.length
                        ? pictures.map(picture => {
                              const createObjectURL =
                                  //@ts-ignore
                                  (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
                              const imageUrl = createObjectURL(picture.img);
                              return (
                                  <div className={classes.imgBox} key={picture.id}>
                                      <div className={classes.hoverBg}>
                                          <IconButton
                                              onClick={() => {
                                                  deletePicture(picture.id);
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      width: 30,
                                                      height: 30,
                                                      backgroundColor: "#FFFFFF",
                                                      position: "relative"
                                                  }}
                                              >
                                                  <span className={classes.close45} />
                                                  <span className={classes.close135} />
                                              </div>
                                          </IconButton>
                                      </div>
                                      <img src={imageUrl} style={{ width: "100%" }} />
                                  </div>
                              );
                          })
                        : null}
                </Box>
                {/*----------------------- ↑ ここまで ---------------------------*/}

                <div className={classes.uploaderWrapper}>
                    <CloudDownload className={classes.uploadIcon} />
                    <ImageUploader
                        name={"image"}
                        buttonText={"ファイルを選択"}
                        label={"画像をドラッグ＆ドロップまたは"}
                        onChange={onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif",".jpeg"]}
                        maxFileSize={5242880}
                        withIcon={false}
                        fileContainerStyle={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            width: "400px",
                            justifyContent: "initial",
                            margin: "40px 40px 40px 70px",
                            boxShadow: "none"
                        }}
                        buttonStyles={{
                            borderRadius: 4,
                            border: "solid 1px #595959",
                            color: "#595959",
                            background: "#fff",
                            padding: 15,
                            marginLeft: 12
                        }}
                    />
                </div>
            </FormContainer>

            <FormContainer title="商品の詳細">
                <FormInLabel label="タイプ" hasHr>
                    <Select
                        classes={{ outlined: classes.selectInput }}
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        onChange={handleChange}
                        defaultValue="productSales"
                    >
                        <MenuItem value="productSales">物販</MenuItem>
                        <MenuItem value="service">サービス</MenuItem>
                        <MenuItem value="coupon">クーポン</MenuItem>
                    </Select>
                </FormInLabel>

                <FormInLabel label="カテゴリー" hasHr>
                    <Select
                        classes={{ outlined: classes.selectInput }}
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        defaultValue="生活用品"
                        onChange={e => {
                            setCategory(e.target.value as "生活用品" | "簡単なサービス" | "近所の美味しい店");
                        }}
                    >
                        <MenuItem value="生活用品">生活用品</MenuItem>
                        <MenuItem value="簡単なサービス">簡単なサービス</MenuItem>
                        <MenuItem value="近所の美味しい店">近所の美味しい店</MenuItem>
                    </Select>
                </FormInLabel>

                <FormInLabel label="在庫数">
                    <OutlinedInput
                        fullWidth
                        name="itemName"
                        multiline
                        margin="dense"
                        className={classes.input}
                        defaultValue={amount}
                        onChange={e => {
                            setAmount(e.target.value);
                        }}
                        inputProps={{ style: { textAlign: "right" } }}
                    />
                </FormInLabel>
            </FormContainer>

            <FormContainer title="商品名と説明">
                <FormInLabel label="商品名" hasHr>
                    <OutlinedInput
                        fullWidth
                        name="itemName"
                        multiline
                        margin="dense"
                        className={classes.input}
                        placeholder="必須●●文字まで"
                        value={itemName}
                        onChange={e => {
                            setItemName(e.target.value);
                        }}
                    />
                </FormInLabel>

                <FormInLabel label="商品の説明">
                    <OutlinedInput
                        multiline={true}
                        notched={true}
                        color="primary"
                        name="description"
                        margin="dense"
                        rows={10}
                        fullWidth
                        className={classes.input}
                        placeholder="任意●●文字まで"
                        value={description}
                        onChange={e => {
                            setDescription(e.target.value);
                        }}
                    />
                </FormInLabel>
            </FormContainer>

            {type === "productSales" && (
                <FormContainer title="配送について">
                    <FormInLabel label="配送方法" align="center" hasHr>
                        <Select
                            classes={{ outlined: classes.selectInput }}
                            fullWidth
                            variant="outlined"
                            className={classes.input}
                            defaultValue={deliveryMethod}
                            onChange={e => {
                                setDeliveryMethod(e.target.value as "posting" | "delivery" | "pickUp");
                            }}
                        >
                            <MenuItem value="posting">メール便など（投函）</MenuItem>
                            <MenuItem value="delivery">宅配便など（手渡し）</MenuItem>
                            <MenuItem value="pickUp">店頭ピックアップ</MenuItem>
                        </Select>
                    </FormInLabel>

                    <FormInLabel label="送料負担" align="center" hasHr>
                        <Select
                            classes={{ outlined: classes.selectInput }}
                            fullWidth
                            variant="outlined"
                            className={classes.input}
                            defaultValue={sippingCost}
                            onChange={e => {
                                setSippingCost(e.target.value as "includeShippingFee" | "cashOnDelivery");
                            }}
                        >
                            <MenuItem value="includeShippingFee">送料込み</MenuItem>
                            <MenuItem value="cashOnDelivery">着払い</MenuItem>
                        </Select>
                    </FormInLabel>

                    <FormInLabel label="発送までの日数" align="center">
                        <Select
                            classes={{ outlined: classes.selectInput }}
                            fullWidth
                            variant="outlined"
                            className={classes.input}
                            defaultValue={deliveryDays}
                            onChange={e => {
                                setDeliveryDays(e.target.value as "oneTwo" | "twoThree" | "fourSeven");
                            }}
                        >
                            <MenuItem value="oneTwo">１〜２日で発送</MenuItem>
                            <MenuItem value="twoThree">２〜３日で発送</MenuItem>
                            <MenuItem value="fourSeven">４〜７日で発送</MenuItem>
                        </Select>
                    </FormInLabel>
                </FormContainer>
            )}

            <FormContainer title="価格">
                <FormInLabel label="税込価格" align="center">
                    <OutlinedInput
                        fullWidth
                        name="itemName"
                        multiline
                        margin="dense"
                        className={classes.input}
                        placeholder="¥0"
                        inputProps={{ style: { textAlign: "right" } }}
                        value={taxIncludePrice}
                        onChange={e => {
                            setTaxIncludePrice(e.target.value);
                        }}
                    />
                </FormInLabel>
            </FormContainer>

            <FormContainer notInput>
                <Button fullWidth className={classes.postingStart} type={"submit"} variant="contained" color="primary">
                    掲載を開始する
                </Button>
                <Button fullWidth type={"button"} className={classes.draftSaving}>
                    下書きとして保存する
                </Button>
            </FormContainer>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth
                PaperProps={{
                    style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "80vh",
                        maxHeight: "80vh"
                    }
                }}
            >
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography align={"center"} variant={"h5"} component={"h3"}>
                            商品を掲載しました。
                        </Typography>
                    </Grid>
                    <Typography
                        align={"center"}
                        variant={"h4"}
                        style={{
                            color: "#DD5F2A"
                        }}
                        component={"h4"}
                    >
                        顧客に自動に配信されません。
                    </Typography>
                    {/* <Grid item xs={12}>
                        <Button
                            style={{
                                color: "white",
                                width: "356px",
                                fontSize: "24px",
                                margin: "0 auto",
                                marginTop: "20px",
                                backgroundColor: "#DD5F2A"
                            }}
                            onClick={() => {
                                handleClick(itemId);
                            }}
                        >
                            メッセージ配信へ
                        </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Button
                            style={{
                                width: "356px",
                                fontSize: "24px",
                                margin: "0 auto",
                                marginTop: "20px",
                                backgroundColor: "#BFBFBF"
                            }}
                            onClick={() => setOpen(false)}
                        >
                            閉じる
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </form>
    );
};

export default RegisterProducts;
