import React,{FC} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import StickySelectTable from '../molecules/StickySelectTable';
import { ButtonGroup, MenuItem, TextField } from '@material-ui/core';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


const Items:FC = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        textBlack: {
            color: 'black',
        },
        buttonDanger: {
            color: 'white',
            fontWeight: 'bold',
            background: '#C04949',
        },
        defaultBotton: {
            borderColor: '#595959',
            fontWeight: 'bold',
            color: '#595959',
        },
        areaInput: {
            width: '46%',
        },
        pagenationButtonGroup: {
            borderColor: '#DDDDDD',
            color: '#FF8C3E',
        },
        textBold: {
            color: '#262626',
            fontWeight: 'bold'
        },
        buttonGroup: {
            width: '80px',
        },
        buttonGroupLarge: {
            width: '170px',
        },
        itemRight:{
            display: 'flex',
            justifyContent: 'right',
        }
    }));

    const categories = ['none', '野菜', '果物'] as const;

    // hooks
    const classes = useStyles();

    return (
        <div style={{background: 'white'}}>
            <Typography className={classes.textBlack}>
                商品名・商品ID・商品コード
            </Typography>
            <TextField
                id="outlined-basic"
                size="small"
                style={{width: '60%', marginBottom: 5}}
                variant="outlined"
            />
            <div
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginBottom: 30,
                }}
            >
                <IndeterminateCheckBoxIcon/>
                <Typography style={{lineHeight: 1.67}} className={classes.textBold}>詳細検索</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.textBlack}>
                        カテゴリ
                    </Typography>
                    <div className={classes.itemRight}>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            defaultValue="none"
                            style={{width: 240}}
                        >
                            {categories.map((category) => {
                                return category !== 'none' ? (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ) : (
                                    <MenuItem key={category} value="none">
                                        選んでください
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textBlack}>
                        登録日
                    </Typography>
                    <div className={classes.itemRight}>
                        <TextField
                            className={classes.areaInput}
                            variant="outlined"
                            size="small"
                            type="date"
                        />
                        <Typography
                            className={classes.textBlack}
                            style={{lineHeight: '40px', padding: '0 5px'}}
                        >
                            〜
                        </Typography>
                        <TextField
                            className={classes.areaInput}
                            variant="outlined"
                            size="small"
                            type="date"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textBlack}>
                        フィルタ
                    </Typography>
                    <FormControlLabel
                        className={classes.textBlack}
                        control={<CheckBox name="checkedB" color="primary"/>}
                        label="公開"
                    />
                    <FormControlLabel
                        className={classes.textBlack}
                        control={<CheckBox name="checkedB" color="primary"/>}
                        label="非公開"
                    />
                    <FormControlLabel
                        className={classes.textBlack}
                        control={<CheckBox name="checkedB" color="primary"/>}
                        label="在庫切れ"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textBlack}>更新日</Typography>
                    <div className={classes.itemRight}>
                        <TextField
                            className={classes.areaInput}
                            variant="outlined"
                            size="small"
                            type="date"
                        />
                        <Typography
                            className={classes.textBlack}
                            style={{lineHeight: '40px', padding: '0 5px'}}
                        >
                            〜
                        </Typography>
                        <TextField
                            className={classes.areaInput}
                            variant="outlined"
                            size="small"
                            type="date"
                        />
                    </div>
                </Grid>
            </Grid>

            <div style={{display: 'flex', margin: '30px 0'}}>
                <Button
                    style={{
                        width: 130,
                        backgroundColor: '#FF8C3E',
                        fontWeight: 'bold',
                        color: '#fff',
                    }}
                    size="large"
                >
                    検索
                </Button>
                <Typography
                    className={classes.textBold}
                    style={{
                        lineHeight: '40px',
                        marginLeft: '15px',
                    }}
                >
                    検索結果 : 56件が該当しました
                </Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <span style={{marginRight: 14}} className={classes.textBlack}>一括操作</span>
                    <ButtonGroup disableElevation variant="outlined">
                        <Button className={classes.defaultBotton}>公開</Button>
                        <Button className={classes.defaultBotton} style={{marginRight: 10}}>非公開</Button>
                    </ButtonGroup>
                    <Button
                        className={classes.defaultBotton}
                        variant="outlined"
                        style={{marginRight: 10}}
                    >
                        廃止
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonDanger}
                    >
                        完全に削除
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        style={{marginRight: '10px'}}
                        variant="outlined"
                        size="small"
                        select
                        defaultValue="10件"
                    >
                        {<MenuItem value="10件">10件</MenuItem>}
                    </TextField>
                    <ButtonGroup color="default">
                        <Button className={classes.defaultBotton} startIcon={<CloudUploadIcon/>}>
                            CSVダウンロード
                        </Button>
                        <Button className={classes.defaultBotton} startIcon={<SettingsIcon/>}>
                            CSV出力設定
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <StickySelectTable/>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <ButtonGroup style={{color: '#ff8c3e'}}>
                    <Button className={classes.pagenationButtonGroup}>
                        前へ
                    </Button>
                    <Button
                        className={classes.pagenationButtonGroup}
                        style={{color: '#fff', backgroundColor: '#FF8C3E'}}
                    >
                        1
                    </Button>
                    <Button className={classes.pagenationButtonGroup}>3</Button>
                    <Button className={classes.pagenationButtonGroup}>
                        次へ
                    </Button>
                </ButtonGroup>
            </Grid>
        </div>
    );
};
export default Items;
