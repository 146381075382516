import React, {FC} from "react"
import {makeStyles} from "@material-ui/styles"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography
} from "@material-ui/core"
import {
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon
} from "@material-ui/icons"

const useStyles = makeStyles({
  root: {
    boxShadow: 'none' 
  },
  AccordionSummaryText: {
    margin: '17px 0!important',
  },
  title: {
    display: "flex"
  },
  helpIcon: {
    color: '#333333',
    fontSize: 20,
    verticalAlign: 'text-bottom'
  },
  divider: {
    height: 3,
    backgroundColor: '#8B726E',
    marginBottom: 33
  },
  details: {
    display: 'block',
    padding: '0 16px'
  }
})

type Props = {
  title: string,
  helpIcon?:boolean
}

const AccordionWrapper:FC<Props> = ({title, helpIcon, children}) => {
  const classes = useStyles()
  return (
    <Accordion className={classes.root}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ minHeight: 48 }}
            classes={{ content: classes.AccordionSummaryText }}
        >
            <Typography className={classes.title}>
                {title}
                {helpIcon && <HelpIcon className={classes.helpIcon} />}
            </Typography>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
    </Accordion>
  )
}

export default AccordionWrapper;