import React, { useContext, useEffect, FC } from "react";
import { db } from "../../assets/Firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Progress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { dateToFormatString } from "../../utils";
import { orangeStrong, toYen } from "../../utils/utils";
import { AuthContext } from "../AuthService";

const DraftsPage: FC = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            maxWidth: 345
        },
        media: {
            height: 0,
            paddingTop: "56.25%" // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
            })
        },
        expandOpen: {
            transform: "rotate(180deg)"
        },
        avatar: {
            backgroundColor: red[500]
        }
    }));

    // hooks
    const classes = useStyles();
    const user = useContext(AuthContext);

    const [value, loading, error] = useCollection(
        db.collection("/drafts").where("userId", "==", user ? user.uid : ""),
        {}
    );

    return (
        <>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <Progress />}
                {value &&
                    value.docs.map(doc => {
                        return (
                            <Grid item xs={4} sm={4} md={4}>
                                <Card className={classes.root} key={doc.id}>
                                    <CardMedia
                                        className={classes.media}
                                        image={doc.data().imageUrls ? doc.data().imageUrls[0] : "#"}
                                        title="Paella dish"
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {doc.data().itemName ? doc.data().itemName : "????"}
                                        </Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            style={{ fontSize: "1.2rem" }}
                                        >
                                            {doc.data().taxIncludePrice ? toYen(doc.data().taxIncludePrice) : "????"}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            style={{ fontSize: "0.89rem", color: orangeStrong }}
                                        >
                                            {doc.data().startAt
                                                ? dateToFormatString(doc.data().startAt.toDate(), "%MM%月%DD%日 %HH%時")
                                                : doc.data().startAt}
                                            〜
                                            {doc.data().endAt
                                                ? dateToFormatString(doc.data().endAt.toDate(), "%MM%月%DD%日 %HH%時")
                                                : doc.data().endtAt}
                                        </Typography>
                                    </CardContent>
                                    <CardActions disableSpacing />
                                </Card>
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};
export default DraftsPage;
