import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import jaLocale from "date-fns/locale/ja";
import { DateTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CancelIcon from '@material-ui/icons/Cancel';


export default function SelectPeriodDate({
            setStartAt,
            setEndAt,
            startAt,
            endAt
        }) {
    return (
            <>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <DateTimePicker
                                      inputVariant="outlined"
                                      format="yyyy/MM/dd HH:mm"
                                      value={startAt}
                                      onChange={setStartAt}
                                      fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2} style={{
                                    margin: "auto"
                                }}>
                                    <Typography align={"center"} variant={"body1"}>
                                        〜
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <DateTimePicker
                                      inputVariant="outlined"
                                      format="yyyy/MM/dd HH:mm"
                                      value={endAt}
                                      onChange={setEndAt}
                                      fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </>
    )
}
