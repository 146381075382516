// Login.js
import React, { useContext, useState, FC } from 'react'
import firebase from '../../assets/Firebase'
import { AuthContext } from '../AuthService'
import { Redirect } from 'react-router-dom'
import LoginDialog from "../organisms/LoginDialog"
import * as H from 'history';

type HistoryProps = {
  history: H.History
}

const Login:FC<HistoryProps> = ({ history }) => {

    type UseInputProps = {
        value:string;
        onChange:(param:React.ChangeEvent<HTMLInputElement>)=>void;
    }

    const useInput = (initialValue:string):UseInputProps => {
        const [value, set] = useState(initialValue)
        return { value, onChange: (e:React.ChangeEvent<HTMLInputElement>) => set(e.target.value) }
    };

    const email = useInput("");
    const password = useInput("");
    const [loginFailed, setLoginFailed] = useState(false);

    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        firebase.auth().signInWithEmailAndPassword(email.value, password.value)
            .then(() => {
                history.push("/")
            })
            .catch(err => {
                console.log(err)
                setLoginFailed(true)
            })
    };

    const user = useContext(AuthContext)

    if (user) {
        return <Redirect to="/" />
    }

    return (
        <div
            style={{
                backgroundColor: "#EDE8E5",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <LoginDialog
                onSubmit={handleSubmit}
                email={email}
                password={password}
                loginFailed={loginFailed}
            />
        </div>
    )
}

export default Login