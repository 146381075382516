import {atom, selector} from 'recoil';


// ログインしているユーザー
export const loggedInUserState = atom({
    key:"loggedInUserState",
    default:{}
})
// 友達
export const usersState = atom({
    key:'usersState',
    default:[]
})

export const groupedUsersState = selector({
    key: "groupedUsersState",
    get: ({get}) => {
        const groupedUsers =  get(usersState).reduce((result,current)=>{
            if(current.group&&current.groups!=='other') {
                if(!result[current.group])result[current.group] = []
                result[current.group].push({
                    ...current,
                })
            }else{
                if(!result['other'])result['other'] = []
                result['other'].push({
                    ...current,
                })
            }
            return result
        },{})
        return groupedUsers
    }
});
export const itemTitlesState = atom({
    key: 'itemTitlesState',
    default: [
        {
            title:"",
            id:""
        }
    ],
})

export const activeSidebarItem = atom({
  key: "currentPath",
  default: "/top"
})