import styled from 'styled-components'

const StyledLoginDialog = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    padding: 5px 0;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        button {
            padding: 5px 8px;
            width: 40px;
            margin-left: 5px;
        }
    }
`

export default StyledLoginDialog;