import React, {FC} from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
      fontSize: 13,
      color: '#000000',
  },
}))

type Props = {
  name: string;
  label: string;
  onClick?: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  register: () => void;
}

const PreOrderCheckBox:FC<Props> =({ register, name, label, onClick })=> {
    const classes = useStyles()
    return (
        <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ marginBottom: 23 }}
        >
            <FormControlLabel
                control={<Checkbox onClick={onClick} inputRef={ register } name={name} style={{ color: '#979797', width: 14, height: 14, marginRight: 3 }} />}
                label={label}
                classes={{ label: classes.label }}
            />
        </Grid>
    )
}

export default PreOrderCheckBox;