import React, { FC } from 'react';
import StyledInputText from "./InputText.styled";

type Props = {
  value?: string;
  type?: "text" | "email" | "password" | "search";
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);
};

const InputText: FC<Props> = ({
  value,
  type,
  name,
  placeholder,
  autoComplete,
  label,
  onChange
}) => (
  <StyledInputText
    value={value}
    type={type}
    name={name}
    placeholder={placeholder}
    autoComplete={autoComplete}
    id={label}
    onChange={onChange}
  />
);

export default InputText;