import React,{FC} from "react";

import {makeStyles} from "@material-ui/styles"
import {
  Grid,
  Typography
} from "@material-ui/core"
import {
  Help as HelpIcon
} from "@material-ui/icons"

const useStyles = makeStyles({
  essential: {
    backgroundColor:"#FF8C3E",
    color:"white",
    marginLeft: "8px",
    fontSize: "14px",
    padding: "0 4px",
    borderRadius:4,
    opacity: 1,
    height: "100%"
  },
  icon: {
    fontSize: '15px',
    color: '#333333',
    verticalAlign: 'text-top',
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  }
})

type Props = {
  title: string,
  required?: boolean,
  helpIcon?: boolean,
  align?: "center" | "flex-start"
}

const GridForm:FC<Props> = ({title, required, helpIcon, children, align="center"})=>{
  const classes = useStyles()
  return (
    <Grid alignItems={align} container item xs={12}>
      <Grid item xs={3} className={classes.titleContainer}>
        <Typography>{title}</Typography>
        {helpIcon && <HelpIcon className={classes.icon} />}
        {required && <Typography className={classes.essential}>必須</Typography>}
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  )
}

export default GridForm;