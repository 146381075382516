import React, { FC } from "react";
import Label from "../atoms/Label";
import { Collapse, Grid, OutlinedInput, Typography } from "@material-ui/core";

import { customerGroupedProfitLabel } from "../../inputData";

type Props = {
    collapseSalesPrice: "same" | "each";
    salesPriceWithoutTax: string;
    register: any;
    errors: any;
};

const CollapseGroupPrice: FC<Props> = ({ collapseSalesPrice, salesPriceWithoutTax, register, errors }) => {
    const group = customerGroupedProfitLabel;
    return (
        <Collapse in={collapseSalesPrice === "each"}>
            <Label text={"顧客グループ別利益率・額"} align={"center"} fontWeight={800} />
            {group.map(item => (
                <Grid container>
                    <Grid item xs={4}>
                        <Label text={item.text} />
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedInput
                            multiline={true}
                            notched={true}
                            type={"number"}
                            value={salesPriceWithoutTax}
                            name={item.name + "Rate"}
                            placeholder={"利益率"}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}>
                        <Typography align={"center"} variant={"body1"}>
                            % +
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <OutlinedInput
                            multiline={true}
                            notched={true}
                            type={"number"}
                            name={item.name + "Price"}
                            placeholder={"利益額"}
                        />
                        {errors[item.name + "Price"] && errors[item.name + "Price"].message}
                    </Grid>
                </Grid>
            ))}
            <Label text={"顧客グループ別販売価格(原価×利益率+利益額)"} align={"center"} fontWeight={800} />
            {group.map(item => (
                <Grid container>
                    <Grid item xs={4}>
                        <Label text={item.text} />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}>
                        <Typography align={"right"} variant={"body1"}>
                            ¥
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <OutlinedInput
                            multiline={true}
                            notched={true}
                            inputRef={register}
                            type={"number"}
                            name={item.name + "SalesPrice"}
                            placeholder={"単価"}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}></Grid>
                </Grid>
            ))}
            {/* end */}
        </Collapse>
    );
};

export default CollapseGroupPrice;
