import React, { FC } from "react";
import Label from "../atoms/Label";
import { Collapse, Grid, OutlinedInput, Typography } from "@material-ui/core";

import { customerGroupedProfitLabel } from "../../inputData";

type Props = {
    collapseDeliveryFee: "each" | "same";
    register: any;
};

const CollapseDelivery: FC<Props> = ({ register, collapseDeliveryFee }) => {
    return (
        <Collapse in={collapseDeliveryFee === "each"}>
            <Label text={"顧客グループ別配送料"} align={"center"} fontWeight={800} />
            {customerGroupedProfitLabel.map(item => (
                <Grid container>
                    <Grid item xs={4}>
                        <Label text={item.text} />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}>
                        <Typography align={"right"} variant={"body1"}>
                            ¥
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <OutlinedInput
                            multiline={true}
                            inputProps={{ ref: register }}
                            notched={true}
                            type={"number"}
                            name={item.name + "DeliveryFee"}
                            placeholder={"単価"}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ margin: "auto" }}></Grid>
                </Grid>
            ))}
        </Collapse>
    );
};

export default CollapseDelivery;
