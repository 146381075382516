import React, {FC, useState, useEffect} from "react";
import {NestDataObject, FieldError} from "react-hook-form"
import {makeStyles} from "@material-ui/styles"
import {
  OutlinedInput,
  Typography
} from "@material-ui/core"

import {ItemHookFormValue} from "../../type"
import GridForm from "../molecules/GridForm";
import PreOrderCheckBox from "../molecules/PreOrderCheckBox";
const useStyles = makeStyles({
  inputSearchContainer: {
    padding: '11px 45px',
  },
})

type Props = {
  register: any,
  errors: NestDataObject<ItemHookFormValue, FieldError>,
  dailyNecessities: boolean
}

const PriceInputGroup:FC<Props> = ({register, errors, dailyNecessities})=>{
  const [taxIncludePrice, setTaxIncludePrice] = useState(0)
  const [taxRate, setTaxRate] = useState(dailyNecessities ? 1.08: 1.1)
  const classes = useStyles()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void=> {
    setTaxIncludePrice(parseInt(e.target.value))
  }

  useEffect(()=>{
    setTaxRate(dailyNecessities ? 1.08: 1.1)
  },[dailyNecessities])

  return (
    <>
    <GridForm title="税込み価格" required helpIcon>
      <Typography
              style={{
                  position: 'absolute',
                  fontSize: 21,
                  backgroundColor: '#EDE8E5',
                  height: '40px',
                  borderRight: 'solid 1px #D1D1D1',
                  padding: '7px 13px',
              }}
          >
              ¥
          </Typography>
          <OutlinedInput
              inputRef={register({required: <p className={"form-error"}>必須項目です</p>})}
              onChange={handleChange}
              fullWidth
              name={"taxIncludePrice"}
              notched={true}
              type={"number"}
              classes={{ input: classes.inputSearchContainer }}
          />
          {errors.taxIncludePrice && errors.taxIncludePrice.message}
    </GridForm>
    <PreOrderCheckBox register={register} name={"dailyNecessities"} label="生活必需品" />
    <GridForm title="税抜き価格" helpIcon>
    <Typography
          style={{
              position: 'absolute',
              fontSize: 21,
              backgroundColor: '#EDE8E5',
              height: '40px',
              borderRight: 'solid 1px #D1D1D1',
              padding: '7px 13px',
          }}
      >
          ¥
      </Typography>
      <OutlinedInput
        inputRef={register}
        value={Math.round(taxIncludePrice / taxRate) || 0}
        disabled
        fullWidth
        name={"salesPriceWithoutTax"}
        notched={true}
        type={"number"}
        classes={{ input: classes.inputSearchContainer }}
      />
      {errors.taxIncludePrice && errors.taxIncludePrice.message}
    </GridForm>
    </>
  )
}

export default PriceInputGroup;