import React, {FC} from "react";
import {FieldError} from "react-hook-form"
// components
import AccordionWrapper from "../organisms/AccordionWrapper"

import {
  Grid,
  TextField
} from "@material-ui/core"

type Props = {
  register: any,
  errorMessage: FieldError | undefined,
}

const FreeAreaTemplate:FC<Props> = ({register, errorMessage})=>{
  return (
    <AccordionWrapper title="フリーエリア" helpIcon>
      <Grid container>
        <Grid item xs={3}>フリーエリア</Grid>
        <Grid item xs={9}>
            <TextField
                multiline={true}
                color={"primary"}
                name="freeArea"
                rows={7}
                fullWidth
                variant="outlined"
                inputProps={{
                  ref: register({
                  maxLength: {
                      value: 1000,
                      message: (<p className={"form-error"}>1000文字以上は入力できません</p>)
                  }
                  })
                }}
            />
            {errorMessage && errorMessage}
        </Grid>
      </Grid>
    </AccordionWrapper>
  )
}

export default FreeAreaTemplate;