// AuthService.js
import React, { useState, useEffect, FC } from "react";
import firebase from "../assets/Firebase";

const AuthContext = React.createContext<firebase.User | null>(null);

const AuthProvider: FC = ({ children }) => {
    const [user, setUser] = useState<firebase.User | null>(null);
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
    }, []);
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
export { AuthContext, AuthProvider };
