import { db } from "../assets/Firebase.js";

export function toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
}

export const usersTestModel1 = {
    DS: {
        members: [
            {
                name: "xxx",
                isMessaged: false,
                id: "1231"
            },
            {
                name: "xxx",
                isMessaged: false,
                id: "09908"
            }
        ]
    },
    other: {
        members: [
            {
                name: "xxx",
                isMessaged: false,
                id: "1231"
            },
            {
                name: "xxx",
                isMessaged: false,
                id: "09908"
            }
        ]
    }
};
export const usersTestModel = {
    DS: {
        isMessagedMember: [
            {
                name: "港南青果バイヤー"
            },
            {
                name: "港南青果バイヤー"
            }
        ],
        isNotMessagedMember: [
            {
                name: "港南青果バイヤー"
            },
            {
                name: "港南青果バイヤー"
            }
        ]
    },
    other: {
        isMessagedMember: [
            {
                name: "港南青果バイヤー"
            },
            {
                name: "港南青果バイヤー"
            }
        ],
        isNotMessagedMember: []
    }
};

export const usersDS = [
    {
        isMessaged: [
            {
                name: "港南青果バイヤー"
            },
            {
                name: "港南青果バイヤー"
            }
        ]
    },
    {
        isNotMessaged: [
            {
                name: "港南青果バイヤー"
            },
            {
                name: "港南青果バイヤー"
            }
        ]
    }
];

export const setIo = () => {
    let start = new Date("2020-05-01");
    let end = new Date("2020-05-04");
    db.collection("category")
        .where("createdAt", "<", end)
        .where("createdAt", ">=", start)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref
                    .update({
                        userId: "nHiN9931XUcfW4QJhrP6uABsQXo1"
                    })
                    .catch(function(error) {
                        console.error("Error removing document: ", error);
                    });
            });
        });
};
export const fixItems = () => {
    let start = new Date("2020-06-09");
    let end = new Date("2020-06-10");
    db.collection("items")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref
                    .update({
                        category: {
                            title: "",
                            id: ""
                        }
                    })
                    .then(function() {
                        console.log("Document successfully deleted!");
                    })
                    .catch(function(error) {
                        console.error("Error removing document: ", error);
                    });
            });
        });
};

export const deleteItems = () => {
    let start = new Date("2020-06-09");
    let end = new Date("2020-06-10");
    db.collection("orders")
        .where("address", "==", "")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref
                    .delete()
                    .then(function() {
                        console.log("Document successfully deleted!");
                    })
                    .catch(function(error) {
                        console.error("Error removing document: ", error);
                    });
            });
        });
};

export function isObjEmpty(obj) {
    return !Object.keys(obj).length;
}

export const userHeader = [
    { title: "ID", field: "id" },
    { title: "ユーザー名", field: "userName" },
    { title: "グループ", field: "group" },
    { title: "送料タイプ", field: "deliveryFeeType" },
    { title: "送料", field: "deliveryFee" },
    { title: "発送時期", field: "ShippingDate" }
];
export const headers = [
    { label: "注文ID", key: "注文ID" },
    { label: "合計金額", key: "合計金額" },
    { label: "注文日時", key: "注文日時" },
    { label: "商品ID", key: "商品ID" },
    { label: "itemName", key: "itemName" }, // 商品名
    { label: "description", key: "description" }, // 商品説明
    { label: "taxIncludePrice", key: "taxIncludePrice" }, // 税込価格
    { label: "numberOfStocks", key: "numberOfStocks" }, // 在庫数
    { label: "orderStartAt", key: "orderStartAt" }, // 受付開始日
    { label: "orderEndAt", key: "orderEndAt" }, // 受付終了日
    { label: "startAt", key: "startAt" }, // 販売開始日
    { label: "endAt", key: "endAt" }, // 販売終了日
    { label: "freeArea", key: "freeArea" }, // フリーエリア
    { label: "createdAt", key: "createdAt" },
    { label: "deliveryFeeOption", key: "deliveryFeeOption" }, // 配送料オプション
    { label: "itemPriceWithoutTax", key: "itemPriceWithoutTax" }, // 税抜価格
    { label: "deliveryFeeWithoutTax", key: "deliveryFeeWithoutTax" }, // 配送料 (税抜き)
    { label: "mibunruiSalesPrice", key: "mibunruiSalesPrice" }, // 未分類販売価格
    { label: "mibunrui1DeliveryFee", key: "mibunrui1DeliveryFee" }, // 未分類配送料
    { label: "mibunruiDeliveryFee", key: "mibunruiDeliveryFee" },
    { label: "mibunrui1SalesPrice", key: "mibunrui1SalesPrice" },
    { label: "salesAmount", key: "salesAmount" }, // 販売価格
    { label: "salesPriceWithoutTax", key: "salesPriceWithoutTax" }, // 税抜販売価格
    { label: "amount", key: "amount" },
    { label: "memo", key: "memo" } // ショップ用メモ
];

export function toYen(price, name) {
    return new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        currencyDisplay: name ? name : "symbol"
    }).format(price ? price : 55);
}

export const createUsers = () => {};
export const gray = "#D0CECE";
export const gray1 = "#A6A6A6";
export const orangeStrong = "#EE5508";
export const lightGray = "#F2F2F2";

export const prefectures = [
    "none",
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県"
];
