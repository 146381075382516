import React, {FC} from "react";

import { Grid, OutlinedInput} from "@material-ui/core"

import AccordionWrapper from "../organisms/AccordionWrapper"

type Props = {
  register: any,
}

const ShopMemo: FC<Props> = ({register})=>{
  return (
    <AccordionWrapper title="ショップ用メモ欄" helpIcon>
      <Grid container alignItems='center' style={{ marginBottom: 10 }}>
        <Grid item xs={12}>
          <OutlinedInput
            multiline={true}
            notched={true}
            color={"primary"}
            name="memo"
            rows={7}
            fullWidth
            inputRef={register}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  )
}

export default ShopMemo;