import React, { FC, useEffect } from "react";
import firebase, { db } from "../../assets/Firebase";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InboxIcon from "@material-ui/icons/Inbox";
import WidgetsIcon from "@material-ui/icons/Widgets";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
    fontStyle: {
        fontSize: "16px",
        color: "#FF8C3E"
    },
    itemStyle: {
        height: "60px",
        display: "flex",
        justifyContent: "space-between"
    },
    iconColor: {
        color: "#442D29",
        marginRight: "14px"
    },
    rightColorLine: {
        backgroundColor: "#DEC6C2"
    }
}));

const Home: FC = () => {
    const classes = useStyles();
    return (
        <>
            <Typography style={{ fontSize: "20px", color: "#262626" }}>ホーム</Typography>
            <Grid container spacing={3} direction="row" justify="space-between">
                <Grid item xs={4}>
                    <List>
                        <ListItem>
                            <Typography style={{ fontSize: "14px", color: "#FF8C3E" }}>受注状況</Typography>
                        </ListItem>
                        <Divider style={{ backgroundColor: "#8B726E", height: "2px" }} />
                        <ListItem className={classes.itemStyle}>
                            <Typography className={classes.fontStyle}>新規受付</Typography>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>13</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                        <ListItem className={classes.itemStyle}>
                            <Typography className={classes.fontStyle}>入金済み</Typography>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>4</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                        <ListItem className={classes.itemStyle}>
                            <Typography className={classes.fontStyle}>対応中</Typography>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>8</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                    </List>
                </Grid>

                <Grid item xs={8} style={{ height: "480px" }}>
                    <List>
                        <ListItem>
                            <Typography style={{ fontSize: "14px", color: "#262626" }}>売上状況</Typography>
                        </ListItem>
                        <Divider style={{ backgroundColor: "#8B726E", height: "2px" }} />
                        <Grid
                            style={{ margin: "auto" }}
                            container
                            spacing={1}
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                        >
                            <ListItem style={{ padding: 0, textAlign: "center", height: "80px" }}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: "24px", color: "#262626" }}>
                                        ￥513,525 / 5 件
                                    </Typography>
                                    <Typography style={{ fontSize: "13px", color: "#262626", margin: "0 auto" }}>
                                        今月の売上金額 / 売上件数
                                    </Typography>
                                </Grid>
                                <span style={{ width: "1px", height: "100%", backgroundColor: "#8B726E" }} />
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: "24px", color: "#262626" }}>
                                        ￥513,525 / 5 件
                                    </Typography>
                                    <Typography style={{ fontSize: "13px", color: "#262626" }}>
                                        今日の売上金額 / 売上件数
                                    </Typography>
                                </Grid>
                                <span style={{ width: "1px", height: "100%", backgroundColor: "#8B726E" }} />
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: "24px", color: "#262626" }}>
                                        ￥513,525 / 5 件
                                    </Typography>
                                    <Typography style={{ fontSize: "13px", color: "#262626" }}>
                                        昨日の売上金額 / 売上件数
                                    </Typography>
                                </Grid>
                            </ListItem>
                        </Grid>
                        <Divider style={{ backgroundColor: "#8B726E" }} />
                        <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ButtonGroup style={{ marginTop: "30px" }} aria-label="outlined primary button group">
                                <Button style={{ width: "120px" }}>週間</Button>
                                <Button style={{ width: "120px" }}>月間</Button>
                                <Button style={{ width: "120px" }}>年間</Button>
                            </ButtonGroup>
                        </span>
                    </List>
                </Grid>
            </Grid>

            <Grid container spacing={3} direction="row" justify="space-between" style={{ marginTop: "40px" }}>
                <Grid item xs={4}>
                    <List>
                        <ListItem>
                            <Typography style={{ fontSize: "14px", color: "#262626" }}>ショップ状況</Typography>
                        </ListItem>
                        <Divider style={{ backgroundColor: "#8B726E", height: "2px" }} />
                        <ListItem className={classes.itemStyle}>
                            <span style={{ display: "flex" }}>
                                <InboxIcon className={classes.iconColor} />
                                <Typography className={classes.fontStyle}>在庫切れ商品数</Typography>
                            </span>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>13</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                        <ListItem className={classes.itemStyle}>
                            <span style={{ display: "flex" }}>
                                <WidgetsIcon className={classes.iconColor} />
                                <Typography className={classes.fontStyle}>取扱商品数</Typography>
                            </span>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>263</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                        <ListItem className={classes.itemStyle}>
                            <span style={{ display: "flex" }}>
                                <SupervisorAccountIcon className={classes.iconColor} />
                                <Typography className={classes.fontStyle}>会員数</Typography>
                            </span>
                            <Typography style={{ fontSize: "24px", color: "#262626" }}>89</Typography>
                        </ListItem>
                        <Divider className={classes.rightColorLine} />
                    </List>
                </Grid>

                <Grid item xs={4}>
                    <List>
                        <ListItem>
                            <Typography style={{ fontSize: "14px", color: "#262626" }}>おすすめのプラグイン</Typography>
                        </ListItem>
                        <Divider style={{ backgroundColor: "#8B726E", height: "2px" }} />
                        <div style={{ position: "relative", overflow: "auto", height: "400px" }}>
                            <ListItem style={{ height: "120px" }}>
                                <img src="/" style={{ height: "90px", width: "120px", marginRight: "10px" }} />
                                <span>
                                    <Typography className={classes.fontStyle} style={{ overflow: "hidden" }}>
                                        複数管理者プラグイン
                                    </Typography>
                                    <Typography style={{ fontSize: "13px", color: "#442D29" }}>
                                        【2018年3月まで】クレジットカード情報の非保持化対応に、トークン決済を検討中の加盟店様は…
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider style={{ backgroundColor: "#8B726E" }} />
                            <ListItem style={{ height: "120px" }}>
                                <img src="/" style={{ height: "90px", width: "120px", marginRight: "10px" }} />
                                <span>
                                    <Typography className={classes.fontStyle}>複数管理者プラグイン</Typography>
                                    <Typography style={{ fontSize: "13px", color: "#442D29" }}>
                                        【2018年3月まで】クレジットカード情報の非保持化対応に、トークン決済を検討中の加盟店様は…
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider style={{ backgroundColor: "#8B726E" }} />
                            <ListItem style={{ height: "120px" }}>
                                <img src="/" style={{ height: "90px", width: "120px", marginRight: "10px" }} />
                                <span>
                                    <Typography className={classes.fontStyle}>複数管理者プラグイン</Typography>
                                    <Typography style={{ fontSize: "13px", color: "#442D29" }}>
                                        【2018年3月まで】クレジットカード情報の非保持化対応に、トークン決済を検討中の加盟店様は…
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider style={{ backgroundColor: "#8B726E" }} />
                            <ListItem style={{ height: "120px" }}>
                                <img src="/" style={{ height: "90px", width: "120px", marginRight: "10px" }} />
                                <span>
                                    <Typography className={classes.fontStyle}>複数管理者プラグイン</Typography>
                                    <Typography style={{ fontSize: "13px", color: "#442D29" }}>
                                        【2018年3月まで】クレジットカード情報の非保持化対応に、トークン決済を検討中の加盟店様は…
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider style={{ backgroundColor: "#8B726E" }} />
                        </div>
                        <Divider style={{ backgroundColor: "#8B726E" }} />
                        <ListItem>
                            <span style={{ display: "flex" }}>
                                <ArrowForwardIosIcon
                                    style={{ fontSize: "10px", margin: "auto 0", marginRight: "13" }}
                                />
                                <Typography style={{ fontSize: "14px", color: "#FF8C3E" }}>オーナーズストア</Typography>
                            </span>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={4}>
                    <List>
                        <ListItem>
                            <Typography style={{ fontSize: "14px", color: "#262626" }}>お知らせ</Typography>
                        </ListItem>
                        <Divider style={{ backgroundColor: "#8B726E", height: "2px" }} />
                        <div style={{ position: "relative", overflow: "auto", height: "400px" }}>
                            <ListItem>
                                <span style={{ display: "flex", justifyContent: "space-between" }}>
                                    <img src="/" style={{ height: "80px", width: "120px", marginRight: "20px" }} />
                                    <span style={{ width: "200px" }}>
                                        <Typography
                                            className={classes.fontStyle}
                                            style={{ height: "45px", overflow: "hidden" }}
                                        >
                                            EC-CUBEペイメントをお使いの方へ、トークン決済申込お手続きのご案内です
                                        </Typography>
                                        <Typography style={{ fontSize: "13px", color: "#442D29", marginTop: "10px" }}>
                                            【2018年3月まで】クレジットカード情報の非保持化対応に、トークン決済を検討中の加盟店様は…
                                        </Typography>
                                    </span>
                                </span>
                            </ListItem>
                            <Divider className={classes.rightColorLine} />
                            <ListItem>
                                <span style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography
                                        align="left"
                                        style={{ fontSize: "14px", color: "#442D29", fontWeight: "bold" }}
                                    >
                                        2017/6/20
                                    </Typography>
                                    <Typography style={{ fontSize: "14px", color: "#442D29", marginTop: "10px" }}>
                                        EC-CUBEをカスタマイズして構築されたECサイトの脆弱性を洗い出す、EC-CUBE公式セキュリティ診断。詳しい資料や報告書サンプルをご用意
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider className={classes.rightColorLine} />
                            <ListItem>
                                <span style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography
                                        align="left"
                                        style={{ fontSize: "14px", color: "#442D29", fontWeight: "bold" }}
                                    >
                                        2017/6/20
                                    </Typography>
                                    <Typography style={{ fontSize: "14px", color: "#442D29", marginTop: "10px" }}>
                                        EC-CUBEをカスタマイズして構築されたECサイトの脆弱性を洗い出す、EC-CUBE公式セキュリティ診断。詳しい資料や報告書サンプルをご用意
                                    </Typography>
                                </span>
                            </ListItem>
                            <Divider className={classes.rightColorLine} />
                            <ListItem>
                                <span style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography
                                        align="left"
                                        style={{ fontSize: "14px", color: "#442D29", fontWeight: "bold" }}
                                    >
                                        2017/6/20
                                    </Typography>
                                    <Typography style={{ fontSize: "14px", color: "#442D29", marginTop: "10px" }}>
                                        EC-CUBEをカスタマイズして構築されたECサイトの脆弱性を洗い出す、EC-CUBE公式セキュリティ診断。詳しい資料や報告書サンプルをご用意
                                    </Typography>
                                </span>
                            </ListItem>
                        </div>
                    </List>
                    <Divider className={classes.rightColorLine} />
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
