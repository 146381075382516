/*eslint-disable*/
import React, { useContext, useEffect, useState, FC } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
// material ui
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import { CloudDownload as CloudDownloadIcon, Help as HelpIcon, Settings as SettingsIcon } from "@material-ui/icons";

import { db } from "../../assets/Firebase";
import { dateToFormatString } from "../../utils";
import { toDateTime, toYen } from "../../utils/utils";
import { AuthContext } from "../AuthService";
import { Items, Item } from "../../type";

type DataProps = {
    合計金額: number;
    商品ID: string;
    注文ID: string;
    注文日時: string;
} & Item;

// yarn add -D liff-type
// https://qiita.com/ufoo68/items/3e6ff0613ebd87a8edd9
// @ts-ignore 上記のinstallが必要そうです
// const liff = window.liff;
const Order: FC = () => {
    const [data, setData] = useState<DataProps[]>();
    let dt = new Date();
    const [startAt, setStartAt] = useState(dt.setMonth(dt.getMonth() - 1));
    const [endAt, setEndAt] = useState(dt.setMonth(dt.getMonth() + 1));

    const user = useContext(AuthContext);
    const classes = useStyles();
    const getProviderId = (userData: firebase.User | null) => {
        if (userData) {
            switch (userData.uid) {
                case "BKgRwRTKfiSvvq3TtSuxE8q4Ioj1":
                    return userData.uid;
                default:
                    return "nHiN9931XUcfW4QJhrP6uABsQXo1";
            }
        } else {
            return "";
        }
    };

    const [value, loading, error] = useCollection(
        db
            .collection("orders")
            .where("providerId", "==", getProviderId(user))
            .orderBy("createdAt", "desc"),
        {}
    );

    const makeNestedData = (value: any) => {
        for (let key in value) {
            if (key.slice(-2) === "At") {
                value[key] = toDateTime(value[key].nanoseconds);
            }
        }
        return value;
    };
    const makeData = (value: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        for (let i = 0; i < value.docs.length; i++) {
            const _doc = value.docs[i];
            // 以下の「as」はfirebase firestoreから取得したデータに明示的に型をつける必要があるため問題ない。
            const items = _doc.data().items ? (_doc.data().items as Items) : ({} as Items);
            let dataProps = Object.keys(items).map(item => {
                let _nestedData = makeNestedData(items[item]);
                let obj = {
                    注文ID: _doc.id,
                    合計金額: _doc.data().sumPrice,
                    注文日時: _doc.data().createdAt.toDate(),
                    商品ID: item,
                    ..._nestedData
                };
                return obj as DataProps;
            });
            return dataProps;
        }
    };
    useEffect(() => {
        if (!loading && value) {
            if (!data) {
                setData(makeData(value));
            }

            // 画面に期間内の注文一覧を表示する処理
            const changedOrder = value.docs.filter(doc => {
                const ItemCreatedAt = doc.data().createdAt.seconds * 1000;
                return ItemCreatedAt >= startAt && ItemCreatedAt <= endAt;
            });
        }
        // CSVに出力するデータを期間内の注文に変更する処理
        if (data) {
            const changedData = data.filter(Item => {
                const orderDate = Date.parse(Item["注文日時"]);
                return orderDate >= startAt && orderDate <= endAt;
            });
        }
    }, [value, startAt, endAt, data]);
    const filterPaymentMethod = (paymentMethod: "請求書払い" | "カード" | "店内で会計") => {
        if (paymentMethod) {
            return paymentMethod.match(/請求書払い|カード/);
        }
        return false;
    };
    // [END handleItemDetailIconClick]
    return (
        <>
            <main className={classes.layout}>
                <Box mb={3}>
                    <Grid container alignItems="flex-end" spacing={2}>
                        <Grid item>
                            <Typography style={{ fontWeight: "bold" }}>受注一覧</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>受注管理</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={7}>
                                <Grid item container style={{ width: "auto" }}>
                                    <Grid item>
                                        <Typography className={classes.label}>
                                            注文番号・お名前・会社名・メールアドレス・電話番号
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <HelpIcon style={{ marginLeft: "5px" }} />
                                    </Grid>
                                </Grid>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.input}
                                    type="text"
                                />
                            </Grid>
                            <Grid item>
                                <Grid item container style={{ width: "auto" }}>
                                    <Grid item>
                                        <Typography className={classes.label}>対応状況</Typography>
                                    </Grid>
                                    <Grid item>
                                        <HelpIcon style={{ fontSize: "14px", marginLeft: "5px" }} />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.checkboxies}>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="新規受付(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="入金済み(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="対応中(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="注文取り消し(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="発注済み(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="購入処理中(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="決済処理中(0)"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="返品(0)"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            className={classes.textBase}
                            control={<Checkbox name="checkedB" color="primary" />}
                            label="詳細検索"
                        />
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>注文者名</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6} style={{ height: "60.8px" }}>
                                <Typography className={classes.label}>支払い方法</Typography>
                                <Grid container className={classes.checkboxies}>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="郵便振替"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="現金書留"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="銀行振り込み"
                                        />
                                    </Grid>
                                    <Grid item className={classes.checkItem}>
                                        <FormControlLabel
                                            className={classes.textBase}
                                            control={<Checkbox name="checkedB" color="primary" />}
                                            label="代金引換"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>注文者名(カナ)</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>注文日</Typography>
                                <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item spacing={2}>
                                        <span>〜</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>注文者会社名</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>入金日</Typography>
                                <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item spacing={2}>
                                        <span>〜</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>メールアドレス</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>更新日</Typography>
                                <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item spacing={2}>
                                        <span>〜</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>電話番号</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>お届け日</Typography>
                                <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item spacing={2}>
                                        <span>〜</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>注文番号</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>購入金額</Typography>
                                <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="text"
                                            value="¥"
                                        />
                                    </Grid>
                                    <Grid item spacing={2}>
                                        <span>〜</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            className={classes.input}
                                            fullWidth
                                            type="text"
                                            value="¥"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={3} xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>お問い合わせ番号</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>購入商品名</Typography>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    className={classes.input}
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                        <Box my={1}>
                            <Typography className={classes.label}>出荷メール</Typography>
                            <Grid container className={classes.checkboxies} spacing={1}>
                                <Grid item spacing={2}>
                                    <FormControlLabel
                                        className={classes.textBase}
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="未送信"
                                    />
                                </Grid>
                                <Grid item spacing={2}>
                                    <FormControlLabel
                                        className={classes.textBase}
                                        control={<Checkbox name="checkedB" color="primary" />}
                                        label="送信済み"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box my={2}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    style={{
                                        backgroundColor: "#FF8136",
                                        color: "#fff",
                                        padding: "8px 45px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    検索
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontWeight: "bold" }}>検索結果：56件が該当しました</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box my={3}>
                    <Box my={2}>
                        <Grid container justify="space-between">
                            <Grid
                                container
                                item
                                alignItems="center"
                                wrap="nowrap"
                                spacing={1}
                                style={{ width: "auto", margin: "5px 0" }}
                            >
                                <Grid item container style={{ width: "auto" }}>
                                    <Grid item>
                                        <Typography className={classes.label} style={{ fontWeight: "bold" }}>
                                            一括操作
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <HelpIcon style={{ fontSize: 16, marginLeft: "5px" }} />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" style={{ padding: "5px 10px" }}>
                                        メールする
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" style={{ padding: "5px 10px" }}>
                                        納品書出力
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Select variant="outlined" defaultValue={10} style={{ height: "32.8px" }}>
                                        <MenuItem value={10}>対応状況の変更</MenuItem>
                                        <MenuItem value={20}>対応状況の変更</MenuItem>
                                        <MenuItem value={30}>対応状況の変更</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                alignItems="center"
                                wrap="nowrap"
                                spacing={1}
                                style={{ width: "auto", margin: "5px 0" }}
                            >
                                <Grid item>
                                    <Select variant="outlined" defaultValue={10} style={{ height: "32.8px" }}>
                                        <MenuItem value={10}>10件</MenuItem>
                                        <MenuItem value={20}>20件</MenuItem>
                                        <MenuItem value={30}>30件</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            startIcon={<CloudDownloadIcon style={{ color: "#3C2825" }} />}
                                            style={{ padding: "5px 10px" }}
                                        >
                                            CSVダウンロード
                                        </Button>
                                        <Button
                                            startIcon={<SettingsIcon style={{ color: "#3C2825" }} />}
                                            style={{ padding: "5px 10px" }}
                                        >
                                            CSV出力設定
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.resultListColumn}>
                        <Grid container justify="space-around" alignItems="center" spacing={2} xs={12}>
                            <Grid item xs={1}>
                                <input type="checkbox" />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.label} style={{ fontWeight: "bold" }}>
                                    注文者
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "14%" }} xs={1}>
                                <Typography className={classes.label} style={{ fontWeight: "bold" }}>
                                    注文日
                                </Typography>
                            </Grid>

                            <Grid item style={{ width: "8%" }} xs={1}>
                                <Typography className={classes.label} align="left" style={{ fontWeight: "bold" }}>
                                    支払方法
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "8%" }} xs={1}>
                                <Typography className={classes.label} align="left" style={{ fontWeight: "bold" }}>
                                    対応状況
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "10%" }} xs={1}>
                                <Typography className={classes.label} align="left" style={{ fontWeight: "bold" }}>
                                    購入金額
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.label} align="left" style={{ fontWeight: "bold" }}>
                                    出荷情報
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "12%" }} xs={1}>
                                <Typography className={classes.label} align="left" style={{ fontWeight: "bold" }}>
                                    お届け先
                                </Typography>
                            </Grid>
                            <Grid container justify="center" alignItems="center" spacing={2} xs={12}>
                                {/* デザイン上の空白 */}
                            </Grid>
                        </Grid>
                    </Box>
                    {/* 検索結果リスト（後から繰り返し処理で表示する） */}
                    {value &&
                        value.docs.map(doc => (
                            <>
                                {filterPaymentMethod(doc.data().paymentMethod) && (
                                    <>
                                        <Box className={classes.resultList} key={doc.id}>
                                            <Grid
                                                container
                                                justify="space-around"
                                                alignItems="center"
                                                spacing={2}
                                                xs={12}
                                            >
                                                <Grid item style={{ width: "3%" }} xs={1} alignContent={"center"}>
                                                    <input type="checkbox" />
                                                </Grid>
                                                <Grid item style={{ width: "14%" }} xs={1}>
                                                    <Typography className={classes.label} style={{ color: "#FF8136" }}>
                                                        {doc.data().userName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography className={classes.label} style={{ color: "#FF8136" }}>
                                                        {dateToFormatString(
                                                            doc.data().createdAt.toDate(),
                                                            "%YYYY%年%MM%月%DD%日 %HH%:%mm%:%ss%"
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography className={classes.label} style={{ color: "#FF8136" }}>
                                                        {doc.data().paymentMethod}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography className={classes.label} style={{ color: "#FF8136" }}>
                                                        未対応
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography className={classes.label} align="left">
                                                        {toYen(doc.data().sumPrice)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{ width: "10%" }} xs={1}>
                                                    <Typography className={classes.label} align="left">
                                                        未出荷
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{ width: "12%" }} xs={1}>
                                                    <Typography className={classes.label} align="left">
                                                        {doc.data().address
                                                            ? doc.data().address["address-level1-1"]
                                                            : ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </>
                        ))}
                    <Grid container justify="center">
                        <ButtonGroup style={{ margin: "10px" }}>
                            <Button style={{ color: "#FF8136" }}>前へ</Button>
                            <Button style={{ color: "#FF8136" }}>1</Button>
                            <Button style={{ color: "#FF8136" }}>2</Button>
                            <Button style={{ color: "#FF8136" }}>3</Button>
                            <Button style={{ color: "#FF8136" }}>次へ</Button>
                        </ButtonGroup>
                    </Grid>
                </Box>
            </main>
        </>
    );
};
const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    layout: {
        width: "auto",
        minWidth: "900px",
        backgroundColor: "#fff",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    label: {
        width: "auto",
        marginBottom: "0",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    textBase: {
        color: "#000000"
    },
    input: {
        width: "100%",
        padding: "0",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    checkboxies: {
        fontWeight: "bold",
        width: "auto",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    checkItem: {
        display: "flex",
        alignItems: "center"
    },
    resultListColumn: {
        borderBottom: "1px solid #806763",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    resultList: {
        borderBottom: "1px solid #806763",
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {}
    },
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    }
}));
export default Order;
