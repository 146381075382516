import React, { useState, FC } from 'react';
import Button from "../../atoms/Button"
import InputText from "../../atoms/InputText"
import StyledAddDialog from "./AddDialog.styled"

type AddDialog = {
    value: string,
    type?: "text"| "email"| "password" | "search";
    placeholder: string,
    autoComplete?: string,
    addClick: () => void,
    isOpen?: boolean,
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void),
}

const AddDialog: FC<AddDialog> = ({
    value,
    type,
    placeholder,
    autoComplete="",
    addClick,
    isOpen,
    onChange
}) => {
    const [failed, setFailed] = useState(false)
    const add = () => {
        if (value !== "") {
            // setFailed(false)
            addClick()
        } else {
            setFailed(true)
        }
    }
    return (
        <>
            {isOpen &&
                <StyledAddDialog>
                    <div>
                        <InputText
                            value={value}
                            type={type}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            onChange={onChange}
                            name={value}
                            label={value}
                        />
                        <Button value="＋" onClick={add} />
                    </div>
                    {failed && <p style={{ fontSize: "16px", color: "#C04949" }}>入力してください</p>}
                </StyledAddDialog>}
        </>
    );
}

export default AddDialog;