import React, { FC } from 'react';
import StyledInputLabel from "./InputLabel.styled"

type Props = {
  labelTitle: string;
  label: string;
}

const InputLabel: FC<Props> = ({ labelTitle, label }) => (
  <StyledInputLabel htmlFor={label}>
      {labelTitle}
  </StyledInputLabel>
);

export default InputLabel;