import React,{ FC } from 'react';
import {useLocation,useHistory} from "react-router-dom";
import FriendListDialog from "../organisms/FriendListDialog";

// material
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

type LocationProps = {
    imageUrl: string;
    title:string;
    message:string;
    itemId:string;
    btnTxt?: string,
}

const MediaCard: FC =()=> {
    const location = useLocation<LocationProps>();
    const history = useHistory();
    const classes = useStyles();

    if(location){
        console.log(location);
    }

    

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Card className={classes.root} style={{margin:"35px auto"}}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={location.state.imageUrl?location.state.imageUrl:""}
                        title="Contemplative Reptile"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {location.state?location.state.title:""}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {location.state?location.state.message:""}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Divider/>
                <CardActions>
                    <Box mx="auto">
                        <Button size="small" color="primary"
                                target="_blank"
                                href={location.state.itemId?`https://demo.eazii.jp/item/${location.state.itemId}`:"https://demo.eazii.jp"}
                        >
                            <Typography align="center">
                                詳細
                            </Typography>
                        </Button>
                    </Box>
                </CardActions>
            </Card>
            <FriendListDialog btnTxt={"配信先を選択する" }
                              title={location.state.title}
                              text={location.state.message}
                              itemId={location.state.itemId}
                              imageUrl={location.state.imageUrl}
            />
            <Button
                fullWidth
                style={{
                    backgroundColor:"#BFBFBF",
                    marginTop:"20px",
                    marginBottom:"20px"
                }}
                onClick={()=>history.goBack()}
            >
                編集する
            </Button>
            <Button
                fullWidth
                style={{
                    backgroundColor:"#BFBFBF",
                    marginBottom:"20px"
                }}
                onClick={e=>history.goBack()}
            >
                破棄する
            </Button>
        </Grid>
    );
}

export default MediaCard